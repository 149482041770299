import React from "react";

import Navbar from "../../../Components/Common/navbar";
import NoticeList from "./NoticeList";
import MobileViewContainer from "../../../Components/MobileViewContainer";

// coupon, voucher 수신하는 페이지
const Notices = () => {

    return (
        <MobileViewContainer>
            <Navbar activeBar={5} />
            <NoticeList />
        </MobileViewContainer>
    );
}

export default Notices;