import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container, Form, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, Table, Modal } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import BgFrame from "../../../Components/Common/BgFrame";
import { clientKey, variantKey } from "../../../common/payments/toss/api";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { API, BACKEND_HOST } from "../../../api";
import DaumPostcode from "react-daum-postcode";
import { calcCouponSaleAmount } from "../../../common/utils/coupon_util";
import CouponShoppingSelectModal from "../../../Components/modal/CouponShoppingSelectModal";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { getAccessToken } from "../../../api/auth";
import SelectCardBox from "../../../Components/Common/SelectCardBox";
import { requestPaymentNicePay } from "../../../common/payments/nicepay/api";
import { getHectoPayParams, requestPaymentHecto } from "../../../common/payments/hecto/api";
import { useUserStore } from "../../../store/store";
import { useAuthContext } from "../../../Components/AuthContext";

const ShoppingOrderProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = React.useState(null);

  const { userInfo } = useAuthContext();

  const [cancelRuleAgree, setCancelRuleAgree] = React.useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = React.useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = React.useState(false);

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  const [addressPopupOpen, setAddressPopupOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  // const [recipient, setRecipient] = useState("");
  // const [recipientPhone, setRecipientPhone] = useState("");
  const [memo, setMemo] = useState("");
  const [selectCoupon, setSelectCoupon] = useState([]);
  const [couponModal, setCouponModal] = useState(false);
  const [targetItem, setTargetItem] = useState({});
  const [tabList, setTabList] = useState([true, true]);

  const [sitePaymentKeys] = useUserStore((state) => [state.sitePaymentKeys]);
  const PGModules = useMemo(() => {
    const _PGModules = [];
    const _site = sitePaymentKeys.find((site) => site.client_id === SUBDOMAIN);
    if (!_site) return _PGModules;
    if (_site.use_hecto) {
      _PGModules.push({ id: 3, code: "hecto", label: "헥토페이" });
    } else if (_site.use_nicepay) {
      _PGModules.push({ id: 2, code: "nicepay", label: "나이스페이" });
    } else if (_site.use_toss) {
      _PGModules.push({ id: 1, code: "toss", label: "토스" });
    }
    return _PGModules;
  }, [sitePaymentKeys]);
  const [selectedPG, setSelectedPG] = useState(null);
  useEffect(() => {
    if (PGModules.length > 0) {
      setSelectedPG(PGModules[0]);
      console.log("PGModules", PGModules);
    }
  }, [PGModules]);

  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);

  const handleTabClick = (index) => {
    setTabList((prevTabList) => {
      const newTabList = [...prevTabList];
      newTabList[index] = !newTabList[index]; // toggle 로직
      return newTabList;
    });
  };

  const toggleAllAgree = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };
  const togglePurchaseAgreeOptions = (agree) => {
    // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const totalOrderPrice = useMemo(() => {
    if (location.state.products.free_delivery_condition > 0) {
      if (location.state.products.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0) < location.state.products.free_delivery_condition) {
        return location.state.products.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0) + location.state.products.delivery_fee;
      }
    } else {
      return location.state.products.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0);
    }
  }, [location.state.products]);

  const toggleShoppingDiscountCouponSelection = (product_id, coupon_id, option_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount = 0) => {
    setSelectCoupon((prev) => {
      let _prev = [...prev];
      const item = { coupon_id: coupon_id, product_id: product_id, option_id: option_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount };
      const selected = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id && item.option_id === option_id);
      const blocked = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id && item.option_id !== option_id);

      if (blocked) {
        return _prev;
      } else {
        _prev = _prev.filter((item) => item.product_id === product_id && item.option_id !== option_id);
        if (!selected) {
          return [..._prev, item];
        } else {
          return _prev;
        }
      }
    });
  };

  useEffect(() => {
    console.log("selectCoupon:", selectCoupon);
  }, [selectCoupon]);

  const totalOrderPriceWithVariation = useMemo(() => {
    let _orderPrice = 0;

    let _total = location.state.products
      .flatMap((item) => {
        if (item.selectVariation)
          return item.selectVariation.map((variation) => {
            let _coupon = selectCoupon.find((coupon) => coupon.product_id === item.id && coupon.option_id === variation.id);

            if (variation.is_required) {
              if (_coupon) {
                return (item.price + variation.price) * variation.quantity - calcCouponSaleAmount(_coupon, (item.price + variation.price) * variation.quantity);
              } else {
                return (item.price + variation.price) * variation.quantity;
              }
            } else {
              if (_coupon) {
                return variation.price * variation.quantity - calcCouponSaleAmount(_coupon, variation.price * variation.quantity);
              } else {
                return variation.price * variation.quantity;
              }
            }
          });
        return item.price * item.quantity;
      })
      .reduce((prev, curr) => prev + curr, 0);
    // console.log("33", _total);

    location.state.products.forEach((product) => {
      if (product.free_delivery_condition > 0) {
        if (_total < product.free_delivery_condition) {
          // console.log("44", _total);
          _orderPrice = _total += product.delivery_fee;
        } else {
          // console.log("55", _total);
          _orderPrice = _total;
        }
      } else {
        // console.log("66", _total);
        _orderPrice = _total;
      }
    });

    return _orderPrice;
  }, [location.state.products, selectCoupon]);

  useEffect(() => {
    (async () => {
      if (!userInfo?.phone_num) return;
      if (paymentMethodsWidgetRef.current) return;

      const customerKey = `${userInfo.phone_num}_${userInfo.id}@amazingpay`
      console.log("customerKey", customerKey)
      console.log("totalOrderPrice", totalOrderPrice)

      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
      if (paymentWidgetRef.current == null) {
        paymentWidgetRef.current = paymentWidget;
      }
      const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
        "#payment-method", { value: totalOrderPrice }, { variantKey: variantKey }
      );
      agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement('#agreement', { variantKey: variantKey });
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, [userInfo, totalOrderPrice]);

  useEffect(() => {
    if (paymentMethodsWidgetRef.current != null) {
      if (location.state.products.find((item) => item.selectVariation)) {
        paymentMethodsWidgetRef.current.updateAmount(totalOrderPriceWithVariation);
      } else {
        paymentMethodsWidgetRef.current.updateAmount(totalOrderPrice);
      }
      console.log("updateAmount totalOrderPrice", totalOrderPrice)
    }
  }, [totalOrderPrice, totalOrderPriceWithVariation, location.state.products]);

  useEffect(() => {
    console.log("location.state:", location.state);
  }, [location]);

  const variationProcessPayment = async () => {
    if (!userInfo) {
      alert("로그인이 필요한 서비스입니다.");
      return;
    }
    const req_data = {
      cart_data: location.state.products.flatMap((item) =>
        item.selectVariation.map((variation) => {
          return {
            variation: variation.id,
            shopping_product: item.id,
            quantity: variation.quantity,
            coupon_id: selectCoupon.find((coupon) => coupon.product_id === item.id && coupon.option_id === variation.id)
              ? selectCoupon.find((coupon) => coupon.product_id === item.id && coupon.option_id === variation.id).coupon_id
              : "",
          };
        })
      ),
      address: address + " " + addressDetail,
      recipient: userInfo.username,
      phone_num: userInfo.phone_num,
      memo: memo,
    };
    console.log("order data:", req_data);

    try {
      const accessToken = getAccessToken();

      if (!accessToken) {
        return;
      }

      const response = await API.post(`/shop/preorder/`, req_data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      console.log("data >>> ", data);

      if ("error" in data) {
        alert(data.error);
        navigate(`/shop`);
        return;
      }

      if (selectedPG.code === "toss") {
        const paymentWidget = paymentWidgetRef.current;
        paymentWidget?.requestPayment({
          orderId: data.orderId,
          orderName: `쇼핑 종합`,
          customerName: userInfo.name,
          // customerEmail: "",
          customerMobilePhone: userInfo.phone_num,
          successUrl: `${BACKEND_HOST}/shop/order/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/shop`,
        });
      } else if (selectedPG.code === "nicepay") {
        requestPaymentNicePay({
          orderId: data.orderId,
          amount: totalOrderPriceWithVariation,
          goodsName: `쇼핑 종합`,
          method: 'card',
          returnUrl: '/shop/order-nice/',
          errorCb: (result) => {
            console.log("errorCb", result);
            alert("결제에 실패하였습니다.");
            navigate(`/shop`);
          }
        })
      } else if (selectedPG.code === "hecto") {
        const encryptedParams = await getHectoPayParams({
          orderId: data.orderId,
          amount: totalOrderPriceWithVariation,
          phone: userInfo.phone_num,
          user_id: userInfo.id,
          email: userInfo.email,
          username: userInfo.username,
        });
        requestPaymentHecto({
          ...encryptedParams,
          mchtName: "어메이징캠프",
          mchtEName: "AmazingCamp",
          pmtPrdtNm: `쇼핑 종합`,
          notiUrl: `${BACKEND_HOST}/shop/order-hecto/`,
          nextUrl: `${BACKEND_HOST}/package/order-done-hecto/`,
          cancUrl: `${BACKEND_HOST}/package/order-fail-hecto/`,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.data);
        alert(`결제에 실패하였습니다. ${error.response.data?.error || String(error.response.data)}`);
      } else {
        alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
      }
      navigate(`/shop`);
    }
  };

  const processPayment = async () => {
    const req_data = {
      cart_data: location.state.products.map((item) => ({
        ...item,
        shopping_product: item.id,
        quantity: item.quantity,
      })),
      address: address + " " + addressDetail,
      recipient: userInfo.name,
      phone_num: userInfo.phone_num,
      memo: memo,
    };

    console.log("order data:", req_data);

    const accessToken = getAccessToken();

    if (!accessToken) {
      alert("로그인이 필요한 서비스입니다.");
      navigate(`/login`);
      return;
    }

    try {
      const response = await API.post(`/shop/preorder/`, req_data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      console.log("data >>> ", data);

      if ("error" in data) {
        alert(data.error);
        navigate(`/shop`);
        return;
      }

      // const paymentWidget = paymentWidgetRef.current;
      // paymentWidget?.requestPayment({
      //   orderId: data.orderId,
      //   orderName: `쇼핑 종합`,
      //   customerName: userInfo.name,
      //   // customerEmail: "",
      //   customerMobilePhone: userInfo.phone_num,
      //   successUrl: `${BACKEND_HOST}/shop/order/`,
      //   failUrl: `https://${SUBDOMAIN}.amazingticket.site/shop`,
      // });
      if (selectedPG.code === "toss") {
        const paymentWidget = paymentWidgetRef.current;
        paymentWidget?.requestPayment({
          orderId: data.orderId,
          orderName: `쇼핑 종합`,
          customerName: userInfo.name,
          // customerEmail: "",
          customerMobilePhone: userInfo.phone_num,
          successUrl: `${BACKEND_HOST}/shop/order/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/shop`,
        });
      } else if (selectedPG.code === "nicepay") {
        requestPaymentNicePay({
          orderId: data.orderId,
          amount: totalOrderPrice,
          goodsName: `쇼핑 종합`,
          method: 'card',
          returnUrl: '/shop/order-nice/',
          errorCb: (result) => {
            console.log("errorCb", result);
            alert("결제에 실패하였습니다.");
            navigate(`/shop`);
          }
        })
      } else if (selectedPG.code === "hecto") {
        const encryptedParams = await getHectoPayParams({
          orderId: data.orderId,
          amount: totalOrderPrice,
          phone: userInfo.phone_num,
          user_id: userInfo.id,
          email: userInfo.email,
          username: userInfo.username,
        });
        requestPaymentHecto({
          ...encryptedParams,
          mchtName: "어메이징캠프",
          mchtEName: "AmazingCamp",
          pmtPrdtNm: `쇼핑 종합`,
          notiUrl: `${BACKEND_HOST}/shop/order-hecto/`,
          nextUrl: `${BACKEND_HOST}/package/order-done-hecto/`,
          cancUrl: `${BACKEND_HOST}/package/order-fail-hecto/`,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.data);
        alert(`결제에 실패하였습니다. ${error.response.data?.error || String(error.response.data)}`);
      } else {
        alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
      }
      navigate(`/shop`);
    }
  };

  React.useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <BgFrame>
      <React.Fragment>
        <div>
          <Container fluid>
            <Row>
              <Col xl={12}>
                <div>
                  <div>
                    <div
                      style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                      onClick={() => handleTabClick(0)}
                    >
                      <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                        주문자
                      </h5>
                      {tabList[0] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                    <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[0] ? "block" : "none" }}>
                      <div>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label htmlFor="billinginfo-firstName" className="form-label">
                                구매자
                              </Label>
                              <Input
                                value={userInfo.name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                className="form-control"
                                id="billinginfo-firstName"
                                placeholder="구매자명"
                                disabled={!userInfo.phone_num}
                              />
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label htmlFor="billinginfo-phone" className="form-label">
                                휴대폰번호
                              </Label>
                              <Input value={userInfo.phone_num ?? ""} type="text" className="form-control" id="billinginfo-phone" placeholder="휴대폰번호" disabled />
                            </div>
                          </Col>

                          <Col sm={12}>
                            <Modal
                              toggle={() => {
                                setAddressPopupOpen((prev) => !prev);
                              }}
                              isOpen={addressPopupOpen}
                              modalClassName="fadeInUp"
                              centered
                            >
                              <div className="modal-header">
                                <h5 className="modal-title mt-0">주소검색</h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setAddressPopupOpen(false);
                                  }}
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <DaumPostcode
                                  style={{}}
                                  onComplete={(data) => {
                                    setAddress(data.address);
                                    setAddressPopupOpen(false);
                                  }}
                                ></DaumPostcode>
                              </div>
                            </Modal>

                            <div className="mb-3">
                              <Label htmlFor="billinginfo-address" className="form-label">
                                배송지 주소
                              </Label>
                              <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "8px" }}>
                                <div style={{ flexGrow: 1, flexShrink: 0 }}>
                                  <Input value={address} style={{ width: "100%", minWidth: "100%" }} type="text" className="form-control" id="billinginfo-address" placeholder="배송지 주소" disabled />
                                </div>
                                <div style={{ flexGrow: 0, flexShrink: 1 }}>
                                  <button
                                    style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", color: "#fff" }}
                                    type="button"
                                    className="btn btn-label right ms-auto nexttab"
                                    onClick={() => {
                                      setAddressPopupOpen(true);
                                    }}
                                  >
                                    <i className="ri-search-line label-icon align-middle" style={{ backgroundColor: "#f6475f", color: "#fff" }}></i>
                                    주소검색
                                  </button>
                                </div>
                              </div>
                              <div style={{ height: "10px" }}></div>
                              <Input
                                value={addressDetail}
                                onChange={(e) => setAddressDetail(e.target.value)}
                                type="text"
                                className="form-control"
                                id="billinginfo-address-detail"
                                placeholder="상세주소"
                              />
                              <div style={{ height: "10px" }}></div>
                              <Input value={memo} onChange={(e) => setMemo(e.target.value)} type="text" className="form-control" id="billinginfo-memo" placeholder="배송 메모" />
                              <div style={{ fontSize: "14px", color: "gray", marginTop: "10px" }}>예) 지점 방문수령으로 oo월 oo일 수령을 원합니다.</div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "10px" }}></div>

                  <div>
                    <div
                      style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                      onClick={() => handleTabClick(1)}
                    >
                      <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                        주문상품
                      </h5>
                      {tabList[1] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                    <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[1] ? "block" : "none" }}>
                      <div>
                        <div>
                          {location.state.products.map((product) => {
                            if (product.selectVariation)
                              return (
                                <Col key={product.id} lg={12} sm={12}>
                                  <div className="" style={{ borderRadius: "5px" }}>
                                    <div className="fw-normal text-wrap mb-1">
                                      <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                        {product.name}
                                      </span>

                                      {product.selectVariation.map((item) => {
                                        if (item.quantity)
                                          if (item.is_required)
                                            return (
                                              <div key={item.id} style={{ borderBottom: "1px solid #ededed", padding: "10px 0", marginTop: "10px" }}>
                                                <div
                                                  className="fw-normal text-wrap mb-1 mx-1"
                                                  style={{ padding: "10px 0", marginTop: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                >
                                                  <span style={{ textAlign: "left" }}>
                                                    {item.value} ({item.price >= 0 ? "+" + item.price.toLocaleString() : item.price.toLocaleString()}원) X {item.quantity}개
                                                  </span>
                                                </div>
                                                <div
                                                  className="fw-bold mt-2 mb-3 btn btn-primary"
                                                  onClick={() => {
                                                    setCouponModal(true);
                                                    setTargetItem({
                                                      product_id: product.id,
                                                      price: product.price + item.price,
                                                      quantity: item.quantity,
                                                      category: product.category,
                                                      option_id: item.id,
                                                    });
                                                  }}
                                                >
                                                  쿠폰선택하기
                                                </div>

                                                {selectCoupon && selectCoupon.length > 0
                                                  ? selectCoupon
                                                    .filter((coupon) => coupon.product_id === product.id && coupon.option_id === item.id)
                                                    .map((coupenSelected) => {
                                                      return (
                                                        <div key={coupenSelected.id} className="fw-normal text-wrap mb-1 mx-1" style={{ padding: "10px 0" }}>
                                                          {coupenSelected ? (
                                                            <>
                                                              <span>쿠폰 적용 </span>
                                                              <span className="d-block" style={{ textAlign: "right", fontWeight: 600 }}>
                                                                {calcCouponSaleAmount(coupenSelected, (product.price + item.price) * item.quantity).toLocaleString()}원 할인
                                                              </span>
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      );
                                                    })
                                                  : null}

                                                <div style={{ display: "flex", justifyContent: "end", marginBottom: "20px" }}>
                                                  <span style={{ fontFamily: "GMarket-M", textAlign: "right" }}>{((product.price + item.price) * item.quantity).toLocaleString()}원</span>
                                                </div>
                                              </div>
                                            );
                                        return (
                                          <div
                                            key={item.id}
                                            className="fw-normal text-wrap mb-1 mx-1"
                                            style={{ borderBottom: "1px solid #ededed", padding: "10px 0", marginTop: "10px", display: "flex", justifyContent: "space-between" }}
                                          >
                                            <span style={{ textAlign: "left" }}>
                                              {item.value} ({item.price >= 0 ? "+" + item.price.toLocaleString() : item.price.toLocaleString()}원) X {item.quantity}개
                                              <span style={{ fontFamily: "Gmarket-M", color: "#F64760" }}>&nbsp;추가상품</span>
                                            </span>
                                            <span style={{ fontFamily: "GMarket-M" }}>{(item.price * item.quantity).toLocaleString()}원</span>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <p style={{ textAlign: "right", marginTop: "20px" }}>
                                      <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#000" }}>
                                        배송비&nbsp;
                                        {product.free_delivery_condition > 0
                                          ? product.free_delivery_condition >
                                            product.selectVariation
                                              .map((item) => {
                                                if (item.is_required) return (product.price + item.price) * item.quantity;
                                                return item.price * item.quantity;
                                              })
                                              .reduce((prev, curr) => prev + curr, 0)
                                            ? product.delivery_fee.toLocaleString()
                                            : 0
                                          : product.delivery_fee.toLocaleString()}
                                        원
                                      </span>
                                    </p>
                                    <p style={{ textAlign: "right", marginTop: "20px" }}>
                                      <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>
                                        결제금액 &nbsp;{totalOrderPriceWithVariation.toLocaleString()}원
                                      </span>
                                    </p>
                                  </div>
                                </Col>
                              );
                            if (!product.quantity) return null;
                            return (
                              <Col key={product.id} lg={12} sm={6}>
                                <div className="" style={{ padding: "20px", borderRadius: "5px", border: "1px solid #444" }}>
                                  <div className="fw-normal text-wrap mb-1">
                                    <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                      {product.name}
                                    </span>

                                    <div
                                      className="fw-normal text-wrap mb-1 mx-1"
                                      style={{ borderBottom: "1px solid #ededed", padding: "10px 0", textAlign: "right", marginTop: "10px" }}
                                    >{`${new Intl.NumberFormat().format(product.price)}원 X ${product.quantity}개`}</div>
                                    <p style={{ textAlign: "right", marginTop: "20px" }}>
                                      <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{`결제금액 ${Number(
                                        product.price * product.quantity
                                      ).toLocaleString()}원`}</span>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "10px" }}></div>

                  <div>
                    <div style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}>
                      <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                        취소 및 환불 규정
                      </h5>
                    </div>
                    <div style={{ border: "1px solid #e9ebec", padding: "20px 20px" }}>
                      <div>
                        <div className="text-muted">
                          <span>배송 관련 질문은 문의게시판 혹은 카카오톡 채널로 문의주시기 바랍니다.</span>
                          <br />
                          <span>불량 및 교환 문의는 반드시 고객센터를 통해서 문의주세요. </span>
                          <p></p>
                        </div>
                        <Row className="gy-3">
                          <Col sm={6}>
                            <label className="form-check-label" htmlFor="-"></label>
                          </Col>
                        </Row>
                        <br />
                        <hr />
                        <Row className="gy-3">
                          <Col sm={12}>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="cancelRuleAgree"
                                checked={cancelRuleAgree}
                                onChange={(e) => {
                                  setCancelRuleAgree(e.target.checked);
                                }}
                              />
                              <label className="form-check-label" htmlFor="cancelRuleAgree">
                                환불 규정에 동의하기
                              </label>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="purchaseAllAgree"
                                checked={purchaseAllAgree}
                                onChange={(e) => {
                                  toggleAllAgree(e.target.checked);
                                }}
                              />
                              <label className="form-check-label" htmlFor="purchaseAllAgree">
                                전체 동의하기
                              </label>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="purchaseOneAgree"
                                checked={purchaseOneAgree}
                                onChange={(e) => {
                                  togglePurchaseAgreeOptions(e.target.checked);
                                }}
                              />
                              <label className="form-check-label" htmlFor="purchaseOneAgree">
                                구매조건 확인 및 결제진행에 동의
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  {PGModules.length > 1 ? <div className="max-w-540 w-100 mt-2">
                    <SelectCardBox
                      items={PGModules}
                      selectedItems={[selectedPG]}
                      onChange={(item) => {
                        setSelectedPG(item);
                      }}
                      isMulti={false}
                    />
                  </div> : null}

                  <div className={"max-w-540 w-100" + (selectedPG && selectedPG.code === "toss" ? " d-block" : " d-none")}>
                    <div id="payment-method" className="w-100" />
                    <div id="agreement" className="w-100" />
                  </div>

                  <div className="d-flex align-items-start gap-3 mt-4 mb-4">
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#f6475f",
                        color: "#fff",
                        fontFamily: "Gmarket-M",
                        margin: "20px auto",
                        width: "100%",
                        padding: "12px 0",
                      }}
                      className="btn right ms-auto nexttab"
                      onClick={async () => {
                        if (!cancelRuleAgree || !purchaseAllAgree) {
                          alert("동의항목을 확인해주세요.");
                          return;
                        }
                        if (!(userInfo.name && userInfo.phone_num) || !address) {
                          alert("구매자명 및 주소를 입력해주세요.");
                          return;
                        }

                        toggleTab(activeTab + 1);

                        if (location.state.products.find((item) => item.selectVariation)) {
                          await variationProcessPayment();
                        } else {
                          await processPayment();
                        }
                      }}
                    >
                      결제하기
                      <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <CouponShoppingSelectModal
          setVisible={setCouponModal}
          visible={couponModal}
          targetItem={targetItem}
          selectCoupon={selectCoupon}
          setSelectCoupon={setSelectCoupon}
          toggleShoppingDiscountCouponSelection={toggleShoppingDiscountCouponSelection}
          navigate={navigate}
        />
      </React.Fragment>
    </BgFrame>
  );
};

export default ShoppingOrderProcess;
