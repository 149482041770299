import React from "react";
import Navbar from "../../Components/Common/navbar";
import NavBottomBar from "../../Components/Common/NavBottomBar";
import Calendar from "./calendar";
import MobileViewContainer from "../../Components/MobileViewContainer";

const ReservationPage = () => {
  return (
    <MobileViewContainer>
      <Navbar activeBar={1} />

      <div className="mt-4">
        <Calendar />
      </div>

      <NavBottomBar activeBar={1} />
    </MobileViewContainer>
  );
};

export default ReservationPage;
