import React from 'react'
import { Container } from 'reactstrap'
import Navbar from '../../../Components/Common/navbar'
import Calendar from '../../Package/calendar'
import MobileViewContainer from "../../../Components/MobileViewContainer";

export default function AmazingPassScreen() {

    return (
        <MobileViewContainer>
            <Navbar />
            <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>

                <div style={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "center", alignItems: "center", marginTop: "20px",
                    marginBottom: "20px"
                }}>
                    <div style={{ fontSize: 19, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>어메이징패스로 예약하기</div>
                </div>


                <div>
                    <Calendar use_amazingpass={true} />
                </div>

            </Container>
        </MobileViewContainer>
    )
}
