import { AxiosResponse } from "axios";
import { API } from "../index";
import { Buffer } from "buffer";
import { UserInfo } from "../type";


export const userService = {
  getUserInfo: async (): Promise<AxiosResponse<UserInfo>> => {
    return await API.get(`/accounts/user-info/`);
  },

  getUserById: async (userId: number, pathname?: string): Promise<AxiosResponse> => {
    const path = pathname ? `?path=${Buffer.from(pathname).toString('base64')}` : '';
    return await API.get(`/accounts/users/${userId}/${path}`);
  },

  getCancelableUsers: async (): Promise<AxiosResponse> => {
    return await API.get(`/sites/cancelable-user-find/`);
  },

  getKakaoChannels: async (): Promise<AxiosResponse> => {
    return await API.get(`/api/kakao/channels/`);
  },

  putSetUserName: async (username: string, phone_num: number | undefined = undefined): Promise<AxiosResponse> => {
    return await API.put(`/accounts/set-username/`, { name: username, phone_num: phone_num });
  },

};