import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCarousel,
  InputGroup,
  InputGroupText,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  UncontrolledAccordion,
} from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import Navbar from "../../Components/Common/navbar";
import { getAccessToken } from "../../api/auth";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
import { packageService } from '../../api/services/package.service';
import MobileViewContainer from "../../Components/MobileViewContainer";

const AddOptionsDetail = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [optionProducts, setOptionProducts] = useState([]);
  const [reservationOptions, setReservationOptions] = useState([]);

  const [reservationData, setReservationData] = useState(null);

  const [optionOpen, setOptionOpen] = useState("1");
  const optionToggle = (id) => {
    if (optionOpen === id) {
      setOptionOpen();
    } else {
      setOptionOpen(id);
    }
  };

  const fetchReservationData = async () => {
    try {
      const reservationResponse = await packageService.getReservationById(id);
      setReservationData(reservationResponse.data);

      const packageResponse = await packageService.getPackageItemById(reservationResponse.data.package_id);
      setOptionProducts(packageResponse.data.options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchReservationData();
    }

    setOptionProducts([]);
  }, [id]);

  const handleReservationOption = (checkValue, reservId, optionId, name, price, quantity, taxfree = false) => {
    // console.log("handleReservationOption", checkValue, reservId, optionId)
    let _reservOptions = [...reservationOptions];
    const _optionIdx = _reservOptions.findIndex((value) => {
      return value.reservId === reservId && value.optionId === optionId;
    });
    if (_optionIdx !== -1) {
      if (!checkValue) {
        // remove
        _reservOptions.splice(_optionIdx, 1);
      }
    } else if (checkValue) {
      // add
      _reservOptions = [
        ...reservationOptions,
        {
          reservId: reservId,
          name: name,
          optionId: optionId,
          price: price,
          quantity: quantity,
          taxfree: taxfree,
        },
      ];
    }

    setReservationOptions(_reservOptions);
  };

  return (
    <React.Fragment>
      <MobileViewContainer>
        <Navbar />
        <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "400px" }}>
          <Row className="px-2">
            <Col lg={12}>
              <Card>
                {reservationData ? (
                  <CardBody className="px-0">
                    <Row className="gx-lg-5">
                      <Col xl={12}>
                        <div className="mt-xl-2 mt-5">
                          <div className="d-flex flex-column">
                            <div className="flex-grow-1">
                              <h4 style={{ fontFamily: "Gmarket-M" }}>{reservationData["name"]}</h4>
                            </div>
                            <div className="mt-2">
                              <div className="mx-2">자리 {reservationData["spot"]}</div>
                              <div className="mx-2">예약시작일 {reservationData["reservationDate"]}</div>
                            </div>
                          </div>

                          {reservationData.options && reservationData.options.length ? (
                            <div className="mt-5">
                              <div className="mb-2">
                                <h5>기존 옵션</h5>
                              </div>
                              <div className="d-flex flex-row justify-content-start gap-4 flex-wrap">
                                {reservationData.options.map((optionItem, idx) => {
                                  return (
                                    <div key={idx} className="form-check my-2" dir="ltr">
                                      <Input type="checkbox" className="form-check-input" id={`${reservationData.id}-${idx}-old`} checked disabled />
                                      <Label for={`${reservationData.id}-${idx}-old`} style={{ fontSize: "12px" }}>
                                        {optionItem.name} <br />+{optionItem.price}원
                                      </Label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}

                          <div className="mt-5">
                            <UncontrolledAccordion open={optionOpen} toggle={optionToggle} defaultOpen="1">
                              <AccordionItem>
                                <AccordionHeader targetId="1" style={{ fontFamily: "Gmarket-M" }}>
                                  추가 옵션
                                </AccordionHeader>

                                <AccordionBody accordionId="1">
                                  {optionProducts.map((optionItem, idx) => {
                                    return (
                                      <div key={idx} className="form-check my-2" dir="ltr">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`${reservationData.id}-${optionItem.id}`}
                                          onChange={(e) => {
                                            handleReservationOption(e.currentTarget.checked, reservationData.id, optionItem.id, optionItem.name, optionItem.price, 1, optionItem.tax_free);
                                          }}
                                        />
                                        <Label for={`${reservationData.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                          {optionItem.name} <br />+{optionItem.price}원
                                        </Label>
                                      </div>
                                    );
                                  })}
                                </AccordionBody>
                              </AccordionItem>
                            </UncontrolledAccordion>
                            {/* <div className="d-flex flex-row justify-content-start gap-4 flex-wrap">
                                {optionProducts.map((optionItem, idx) => {
                                  return (
                                    <div key={idx} className="form-check my-2" dir="ltr">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`${reservationData.id}-${optionItem.id}`}
                                        onChange={(e) => {
                                          handleReservationOption(e.currentTarget.checked, reservationData.id, optionItem.id, optionItem.name, optionItem.price);
                                        }}
                                      />
                                      <Label for={`${reservationData.id}-${optionItem.id}`} style={{ fontSize: "12px" }}>
                                        {optionItem.name} <br />+{optionItem.price}원
                                      </Label>
                                    </div>
                                  );
                                })}
                              </div> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                ) : (
                  <div>loading...</div>
                )}
              </Card>
            </Col>
          </Row>
          {/* 
            <div style={{ position: "fixed", bottom: "0", left: "0", right: "0", zIndex: "4" }}>
              <Button
                color="danger"
                className="btn fw-bold fs-15"
                style={{ width: "100%" }}
                size="lg"
                onClick={() => {
                  navigate(`/add-options-order`, {
                    state: {
                      reservationName: reservationData["name"],
                      spotName: reservationData["spot"],
                      reservDate: reservationData["reservationDate"],
                      reservId: reservationData.id,
                      options: reservationOptions,
                    },
                  });
                }}
                disabled={!reservationOptions.length}
              >
                추가옵션 구매하기
              </Button>
            </div> */}

          <Row
            className="position-fixed d-flex flex-row justify-content-center"
            style={{
              maxWidth: "640px",
              bottom: "0px",
              width: "100%",
              zIndex: 999,
              backgroundColor: "#fff",
              paddingBottom: "25px",
              paddingTop: "25px",
              alignItems: "center",
              borderTop: "1px solid #ededed",
            }}
          >
            {reservationOptions.length > 0 ? (
              <div style={{ maxHeight: "200px", overflowY: "scroll", width: "100%" }}>
                {reservationOptions.map((options, index) => (
                  <div
                    key={"reservationOptions" + index}
                    style={{
                      width: "95%",
                      padding: "20px 20px",
                      margin: "8px auto",
                      borderRadius: "10px",
                      borderBottom: "1px solid #ededed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {options.name}

                    <QuantityButtonInput
                      quantity={reservationOptions[index].quantity}
                      detailPage={true}
                      onChange={(value) => {
                        const _reservationOptions = [...reservationOptions];
                        console.log("_reservationOptions", _reservationOptions);
                        if (_reservationOptions[index].type === "extra") {
                          if (value > reservationData.max_personnel) {
                            alert(`최대 ${reservationData.max_personnel}개까지 선택 가능합니다.`);
                            return;
                          }
                        }
                        _reservationOptions[index].quantity = value;
                        setReservationOptions(_reservationOptions);
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : null}
            <Button
              // color="danger"
              className="btn fw-bold fs-16"
              style={{
                width: "95%",
                backgroundColor: "#F6475F",
                border: "none",
                padding: "14px 0px",
                borderRadius: "12px",
                fontFamily: "Gmarket-M",
              }}
              size="lg"
              onClick={() => {
                navigate(`/add-options-order`, {
                  state: {
                    reservationName: reservationData["name"],
                    spotName: reservationData["spot"],
                    reservDate: reservationData["reservationDate"],
                    reservId: reservationData.id,
                    options: reservationOptions,
                  },
                });
              }}
              disabled={!reservationOptions.length}
            >
              구매하기
            </Button>
          </Row>

          {/* <div style={{ position: "fixed", bottom: "0", left: "50%", right: "0", zIndex: "4", maxWidth: "640px", width: "100%", transform: "translateX(-50%)" }}>
              <button
                type="button"
                className="btn ms-auto nexttab"
                style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                onClick={() => {
                  navigate(`/add-options-order`, {
                    state: {
                      reservationName: reservationData["name"],
                      spotName: reservationData["spot"],
                      reservDate: reservationData["reservationDate"],
                      reservId: reservationData.id,
                      options: reservationOptions,
                    },
                  });
                }}
                disabled={!reservationOptions.length}
              >
                결제하기
                <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
              </button>
            </div> */}
        </Container>
      </MobileViewContainer>
    </React.Fragment>
  );
};

export default AddOptionsDetail;
