import { API } from '../index';

// Content Management (Detail pages)
export const updateContentImage = async (type: string, itemId: string, images: string[]) => {
  return API.put(`/${type}/image/${itemId}/`, { image: images });
};

export const updateContentDescription = async (type: string, itemId: string, description: string) => {
  return API.put(`/${type}/descript/${itemId}/`, { descript: description });
};

export const updateContentInfo = async (type: string, itemId: string, info: string) => {
  return API.put(`/${type}/info/${itemId}/`, { usingInfo: info });
};

// Notice/Announcements
export const getAnnouncements = async () => {
  return API.get('/notice/announce/');
};

export const getAnnouncementRead = async (id: string) => {
  return API.get(`/notice/announce-read/?id=${id}`);
};