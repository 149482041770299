import React, { useEffect, useState, useCallback } from "react";
import Navbar from "../../../Components/Common/navbar";
import { eventService } from "../../../api/services/event.service";
import CardSection from "../../Ticket/CardSection";
import MobileViewContainer from "../../../Components/MobileViewContainer";

export default function AmazingPassPage() {
  const [infoData, setInfoData] = React.useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await eventService.getAmazingPassInfo();
      const Imgdata = response.data;

      setInfoData(Imgdata.data);
      console.log("get tickets", Imgdata.data.images);
    } catch (error) {
      console.error("Fetching tickets failed", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MobileViewContainer>
      <Navbar />
      {infoData.images && infoData.images.map((item, idx) => <img key={idx} src={item.src} alt="pass" style={{ width: "100%" }} />)}
      <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
        <CardSection amazingpass={true} />
      </div>
    </MobileViewContainer>
  );
}
