import { AxiosResponse } from "axios";
import { API } from "../index";
import { Page } from "../type";

export const couponService = {
  getCouponBook: async (): Promise<AxiosResponse> => {
    return await API.get(`/coupon/book/`);
  },

  postCouponDownById: async (couponId: number): Promise<AxiosResponse> => {
    return await API.post(`/coupon/book/download/${couponId}/`);
  },

  getCouponsByPage: async (page: number): Promise<AxiosResponse> => {
    return await API.get(`/coupon/user/?page=${page}`);
  },

  getCouponGiftList: async (pageParam: number): Promise<AxiosResponse> => {
    return await API.get(`/coupon/user/?page=${pageParam}&gifted=true`);
  },

  putReceptCouponById: async (couponId: number): Promise<AxiosResponse> => {
    return await API.put(`/coupon/gift/reception/${couponId}/`);
  },

  putCouponSendCancelById: async (couponId: number): Promise<AxiosResponse> => {
    return await API.put(`/coupon/gift/cancel/${couponId}/`);
  },

};

export const sendUserCouponGift = async (couponId: string, phoneNum: string) => {
  return API.post("/coupon/gift/send/", {
    user_coupon_id: couponId,
    phone_num: phoneNum,
  });
};

export const sendUserCouponKakao = async (couponId: string, kakaoId: string) => {
  return API.post("/coupon/gift/send/kakao/", {
    coupon_id: couponId,
    kakao_id: kakaoId,
  });
};

export const giftCouponByUrl = async (couponId: string) => {
  return API.post(`/coupon/gift/send/url/`, {
    user_coupon_id: couponId,
  });
};

export const getUserPreCoupons = async (page: number = 1, state: string = "사용안함"): Promise<AxiosResponse> => {
  return await API.get(`/precoupon/user/`, {
    params: {
      page: page,
      state: state
    }
  });
}