import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Button, Alert, Form, Label, Input } from "reactstrap";
import { ticketService } from "../../api/services/ticket.service";

const AmazingPassInfoModal = ({ info, setInfo, visible, setVisible, refetch }) => {
  const togModal = () => {
    setVisible(!visible);
  };

  const handleRefund = async (couponData) => {
    if (!couponData?.transfered_ticket) return;

    const confirmed = window.confirm("환불하시겠습니까?");

    if (confirmed) {
      try {
        await ticketService.requestTicketCancel(couponData.transfered_ticket.id);
        setVisible(false);
        setInfo({});
        refetch();
      } catch (error) {
        console.log("requestTicketCancel", error);
        if (error.response?.data?.error) {
          alert(error.response.data.error);
        } else {
          alert("환불에 실패했습니다.");
        }
      }
    }
  };

  return (
    <React.Fragment>
      {Object.keys(info).length !== 0 ? (
        <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
          <div className="mx-3 my-3">
            <div className="my-3 d-flex justify-content-center  "></div>
            <ul className="list-group">
              <li className="list-group-item">쿠폰이름: {info?.precoupon_name}</li>

              <li className="list-group-item">
                예약가능기간: {info.coupon_start_date} ~ {info.coupon_end_date}
              </li>
              <li className="list-group-item" style={{ height: "150px", overflowY: "scroll" }}>
                <p style={{ fontWeight: "bold" }}>*사용 가능 패키지*</p>
                {info?.available_package_names.map((item, idx) => (
                  <p key={idx}>{item.name} ({item.site})</p>
                ))}
              </li>

              <li className="list-group-item" style={{ height: "200px", overflowY: "scroll" }}>
                <div style={{ textAlign: "left", fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: info.description }} />
              </li>
            </ul>

            {/* <div style={{ marginTop: "12px" }}>
                <span style={{ fontWeight: "bold", color: "#ff5a5f" }}>예약권 사용은 앱에서만 가능합니다!</span>
            </div> */}

            <div style={{ marginTop: "18px" }}></div>
          </div>
          <div className="d-flex justify-content-center mx-1 pb-4">
            {info?.transfered_ticket ? (
              <Button
                color="btn btn-light ms-2 my-2 mx-2"
                style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                onClick={() => {
                  handleRefund(info);
                }}
              >
                환불하기
              </Button>
            ) : null}

            <Button
              color="btn btn-light ms-2 my-2 mx-2"
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
              onClick={() => {
                setInfo({});
                setVisible(false);
              }}
            >
              닫기
            </Button>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
export default AmazingPassInfoModal;
