import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap";
import MobileViewContainer from "../../../Components/MobileViewContainer";
import Navbar from "../../../Components/Common/navbar";

const OrderFail = () => {
    const navigate = useNavigate();

    return (
        <MobileViewContainer>
            <Navbar />
            <h3 className="border-success text-center my-3 fw-bold mt-4" style={{ fontFamily: "Gmarket-M" }}>
                주문실패
            </h3>

            <Card>
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <p className="text-center">주문이 실패하였습니다.</p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div className="d-flex justify-content-center">
                <Button onClick={() => navigate("/my-page")} color="success" style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", boxShadow: "none", border: "none" }}>
                    마이페이지 가기
                </Button>
            </div>
        </MobileViewContainer>
    );
};
export default OrderFail;
