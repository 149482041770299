import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";

import Navbar from '../../Components/Common/navbar';
import Coupon from './purchaseList/Coupon';
import UserVoucher from './purchaseList/UserVoucher';
import classnames from "classnames";
import MobileViewContainer from "../../Components/MobileViewContainer";

export default function Purchases() {
    const location = useLocation();

    // Custom Tabs Bordered
    const [customActiveTab, setcustomActiveTab] = useState("2");
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <MobileViewContainer>
                <Navbar></Navbar>
                <div>
                    <Row className="justify-content-center">
                        <Col xxl={8} xl={10} md={11} sm={11}>
                            <Card className='bg-light bg-opacity-50'>
                                <CardBody>
                                    <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3 bg-body bg-opacity-10">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                쿠폰
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                교환권
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                                <div>
                                    {customActiveTab === "1" ? <Coupon /> : customActiveTab === "2" ? <UserVoucher /> : <></>}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </MobileViewContainer>
        </React.Fragment>
    )
}
