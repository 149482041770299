import React, { useEffect } from "react";

import Navbar from "../../../Components/Common/navbar";
import Home from "./home";
import Footer from "./footer";
import { useUserStore } from "../../../store/store";
import MainAppDownload from "../../MainAppDownload";
import NavBottomBar from "../../../Components/Common/NavBottomBar";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const Index = ({ isWebPurchase, isWebPurchaseTicket, isManaged }) => {
  const [permission] = useUserStore((state) => [state.permission]);

  return (
    <MobileViewContainer>
      <Navbar activeBar={0} permission={permission} />

      {!isWebPurchase && !isWebPurchaseTicket && isManaged && <MainAppDownload />}

      <Home permission={permission} />
      <Footer permission={permission} />
      <NavBottomBar activeBar={0} />
    </MobileViewContainer>
  );
};

export default Index;
