import { SITE_UID } from "../assets/meta/meta";
import { REDIRECT_URI, REST_API_KEY } from "../common/social/kakao/api";

export function isUserLogin() {
  const token = window.localStorage.getItem("access_token");
  if (token) return true;
  else return false;
}

export function setAccessToken(token) {
  if (token) window.localStorage.setItem("access_token", token);
}

export function getAccessToken() {
  const token = window.localStorage.getItem("access_token");
  return token;
}

export function removeAccessToken() {
  window.localStorage.setItem("access_token", "");
}

export function setRefreshToken(token) {
  if (token) window.localStorage.setItem("refresh_token", token);
}

export function getRefreshToken() {
  const token = window.localStorage.getItem("refresh_token");
  return token;
}

export function removeRefreshToken() {
  window.localStorage.setItem("refresh_token", "");
}

export function setKakaoAccessToken(token) {
  // console.log("kakao token renewed!!", token)
  if (token) window.localStorage.setItem("kakao_access_token", token);
}
export function getKakaoAccessToken() {
  const token = window.localStorage.getItem("kakao_access_token");
  return token;
}

export function removeKakaoAccessToken() {
  window.localStorage.setItem("kakao_access_token", "");
}

// 카카오톡 기본 동의항목: profile, phone_number, plusfriends
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${SITE_UID}`;

// 카카오톡 추가 동의항목: friends (친구목록)
export const KAKAO_EXTRA_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=friends,plusfriends&state=${SITE_UID}`;

export const KAKAO_JS_KEY = "2c81c51aef9522d0b4284a16d493b628"

