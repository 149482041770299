import { AxiosResponse } from "axios";
import { API, BACKEND_HOST } from "../index";

export const eventService = {
  getAmazingPassInfo: async (): Promise<AxiosResponse> => {
    return await API.get(`/ticket/get-event-amazingpass-info/`);
  },

  getBookRule: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/voucher/book-rule/${id}/`);
  },

  checkBook: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/voucher/book/check/${id}/`);
  },

  processEventItem: async (type: "coupon" | "voucher", itemId: string): Promise<AxiosResponse> => {
    return await API.post(`/${type}/process/${itemId}/`);
  },

  getUserNonReceipt: async (type: "coupon" | "voucher"): Promise<AxiosResponse> => {
    return await API.get(`/${type}/user-non-receipt/`);
  }
};