import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Input, Modal, Nav, NavItem, NavLink, Tooltip } from "reactstrap";
import { API, BACKEND_HOST, SITE_URL } from "../../api";
import classnames from "classnames";
import GiftPhoneNumList from "../../pages/UserPage/purchaseList/GiftPhoneNumList";
import GiftB2BList from "../../pages/UserPage/purchaseList/GiftB2BList";
import { getAccessToken } from "../../api/auth";
import { shareKakao } from "../../common/social/kakao/api";
import { handleCopyClipBoard } from "../../common/utils/clipboard";

const B2BVoucherGiftModal = ({ info, setInfo, visible, setVisible, needRefresh }) => {
  const togModal = () => setVisible(!visible);

  const [resetPinCode, setResetPinCode] = useState(false);

  const [customActiveTab, setcustomActiveTab] = useState("2");
  const [sendCount, setSendCount] = useState(1);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const sendUserVoucher = async (voucherId, phoneNum) => {
    // const phone_num = phoneNum.replaceAll("-", "");

    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/voucher/stock/send/",
        {
          stock_id: voucherId,
          count: sendCount,
          user_phones: phoneNum,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.data;
      return data;
    } catch (error) {
      console.error(error);
      // Handle errors as needed
      throw error;
    }
  };

  const shareB2BVoucher = async (stockId, count, reset = false) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/voucher/stock/share/",
        {
          stock_id: stockId,
          count: count,
          reset: reset,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const changeB2BVoucherState = async (stockId, state) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.put(
        `/voucher/stock/change/${stockId}/`,
        {
          stock_id: stockId,
          state: state,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  if (!Object.keys(info).length) return null;

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
        <div className="my-3" style={{ width: "100%", maxWidth: "640px", paddingInline: "8px" }}>
          <div className="my-1 d-flex justify-content-center">
            <h4>B2B 교환권 배포</h4>
          </div>
          <ul className="list-group">
            <li className="list-group-item">쿠폰이름: {info?.name}</li>
            <li className="list-group-item">
              <div className="">지점: {info?.site_name}</div>
              <div className="">배포가능 개수: {info?.stock}</div>
              {/* <div className="">사용가능한 지점 : {info?.state}</div> */}
              {/* <div className="">할인금액 : {info?.voucher.discount_type === "price" ? `${info.voucher.discount_amount.toLocaleString()}원 할인쿠폰` : `${info.voucher.discount_rate}% 할인쿠폰`}</div> */}
              <div className="">만료기한 : {info.voucher.expire_date === null ? "없음" : info.voucher.expire_date}</div>
              {/* {info.voucher.bound_type === "reservation" ? (
                <div className="">
                  예약가능기간: {info.voucher.use_start_date} ~ {info.voucher.use_end_date}
                </div>
              ) : null} */}

              {/* <div className="">사용정보 : {info.voucher.min_purchase.toLocaleString()}원 이상 구매시 사용가능</div> */}
            </li>

            <li className="list-group-item">
              발송개수(1인당) :
              <Input
                style={{ width: "100px", display: "inline-block", marginLeft: "10px" }}
                value={sendCount}
                onChange={(e) => {
                  setSendCount(e.currentTarget.value);
                }}
                type="number"
                className="form-control"
                placeholder="개수입력"
              />
            </li>
          </ul>

          <div style={{ marginTop: "24px" }}></div>

          <ul className="list-group">
            {info.share_pin_code ? (
              <>
                <li className="list-group-item" style={{ overflowWrap: "break-word" }}>
                  <label style={{ marginBottom: "3px", color: "#6d6d6d" }} htmlFor="shareUrl">
                    공유주소{" "}
                  </label>
                  <div className="d-flex justify-content-between align-items-center gap-1">
                    <input
                      id="shareUrl"
                      type="text"
                      value={`${SITE_URL}/promotion/voucher/${info.share_pin_code}/`}
                      style={{ width: "100%", padding: "5px", border: "1px solid #ccc", borderRadius: "5px", height: "36px", flexShrink: 1 }}
                    />
                    <button
                      className="btn btn-primary"
                      style={{ height: "36px", flexShrink: 0, display: "flex", gap: "3px" }}
                      onClick={() => {
                        handleCopyClipBoard(`${SITE_URL}/promotion/voucher/${info.share_pin_code}/`);
                      }}
                    >
                      <i className="ri-file-copy-2-fill"></i>
                      복사
                    </button>
                  </div>
                </li>
                <li className="list-group-item">공유 개수(1인당): {info.share_count}개</li>
                <li className="list-group-item">
                  <div className="d-flex align-items-center gap-2" style={{ justifyContent: "space-between" }}>
                    <div>
                      <span>
                        공유상태: <span style={{ color: "#f6475f", fontFamily: "Gmarket-M" }}>{info.share_state}</span>
                      </span>
                      <Button
                        className=""
                        color="light"
                        id={"Tooltip-1"}
                        style={{
                          padding: 0,
                          borderRadius: "50%",
                          width: "18px",
                          height: "18px",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "-15px",
                          marginLeft: "10px",
                          backgroundColor: "#fff9fa",
                        }}
                      >
                        <span style={{ textAlign: "center", color: "#ff5a5f", fontSize: "11px" }}>?</span>
                      </Button>

                      <Tooltip
                        id="tooltip-right"
                        className="custom-tooltip"
                        hideArrow={true}
                        placement={"right"}
                        isOpen={tooltipOpen}
                        target={"Tooltip-1"}
                        toggle={toggle}
                        style={{ maxWidth: "300px", textAlign: "left", backgroundColor: "#ededed", color: "#1d1d1d" }}
                      >
                        *노출중: 현재 수신인이 쿠폰 다운로드 가능한 상태입니다.
                        <br />
                        *일시중지: 현재 수신인의 쿠폰 다운로드가 불가한 상태입니다.
                        <br />
                        *공유종료: 현재 수신인의 쿠폰 다운로드가 불가한 상태이며, 이전 공유주소로는 쿠폰을 받을 수 없습니다.
                      </Tooltip>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      {info.share_state === "노출중" ? (
                        <Button
                          size="sm"
                          color="soft-warning"
                          className="d-flex align-items-center gap-1"
                          onClick={() => {
                            changeB2BVoucherState(info.id, "일시중지")
                              .then((data) => {
                                console.log("changeB2BVoucherState", data);
                                setVisible(false);
                                needRefresh();
                              })
                              .catch((error) => {
                                console.error(error);
                                alert("배포중지에 실패했습니다.");
                              });
                          }}
                        >
                          <i className="ri-pause-fill"></i>
                          일시중지
                        </Button>
                      ) : null}
                      {info.share_state === "일시중지" ? (
                        <Button
                          size="sm"
                          color="soft-success"
                          className="d-flex align-items-center gap-1"
                          onClick={() => {
                            changeB2BVoucherState(info.id, "노출중")
                              .then((data) => {
                                console.log("changeB2BVoucherState", data);
                                setVisible(false);
                                needRefresh();
                              })
                              .catch((error) => {
                                console.error(error);
                                alert("배포재개에 실패했습니다.");
                              });
                          }}
                        >
                          <i className="ri-pause-fill"></i>
                          배포재개
                        </Button>
                      ) : null}
                      {info.share_state === "노출중" || info.share_state === "일시중지" ? (
                        <Button
                          size="sm"
                          color="soft-danger"
                          className="d-flex align-items-center gap-1"
                          onClick={() => {
                            if (!window.confirm("배포중지할 경우, 이전 공유주소로는 쿠폰을 받을 수 없습니다. 배포중지하시겠습니까?")) return;

                            changeB2BVoucherState(info.id, "숨김")
                              .then((data) => {
                                console.log("changeB2BVoucherState", data);
                                setVisible(false);
                                needRefresh();
                              })
                              .catch((error) => {
                                console.error(error);
                                alert("공유종료에 실패했습니다.");
                              });
                          }}
                        >
                          <i className="ri-stop-fill"></i>
                          공유종료
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </li>
              </>
            ) : null}

            <li className="list-group-item">
              <div className="d-flex justify-content-end align-items-center gap-2">
                {/* {info.share_pin_code ? (
                  <div>
                    <label htmlFor="resetPinCode" style={{ margin: 0, color: "#6d6d6d" }}>
                      공유주소 리셋하기
                    </label>
                    <Input type="checkbox" id="resetPinCode" name="resetPinCode" value={resetPinCode} onChange={(e) => setResetPinCode(e.target.checked)} style={{ marginLeft: "10px" }} />
                  </div>
                ) : null} */}
                <Button
                  style={{
                    fontFamily: "Gmarket-M",
                    backgroundColor: "#f9e000",
                    borderWidth: 0,
                    color: "#444",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 20px",
                    gap: "10px",
                  }}
                  onClick={() => {
                    if (resetPinCode && !window.confirm("핀코드를 리셋할 경우, 이전 핀코드로는 쿠폰을 받을 수 없으며, 1인당 받은 쿠폰 수도 초기화됩니다. 핀코드를 리셋하시겠습니까?")) return;

                    shareB2BVoucher(info.id, sendCount, resetPinCode)
                      .then((data) => {
                        console.log("shareB2BVoucher", data);
                        shareKakao({
                          title: `${info.voucher_name}`,
                          description: `받을 수 쿠폰이 있습니다!`,
                          imageUrl: info.voucher.image ? info.voucher?.image : "",
                          shareUrl: `${SITE_URL}/promotion/voucher/${data.share_pin_code}/`,
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                        alert("선물하기에 실패했습니다.");
                      })
                      .finally(() => {
                        setVisible(false);
                        needRefresh();
                      });
                  }}
                >
                  <i className="ri-gift-fill"></i>
                  카카오톡으로 선물링크 공유
                </Button>
              </div>
            </li>
          </ul>
          <div style={{ marginTop: "24px" }}></div>

          <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10" style={{ alignItems: "end" }}>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                대량 선물
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                전화번호로 선물
              </NavLink>
            </NavItem>
          </Nav>

          {customActiveTab === "1" ? <GiftB2BList sendItem={sendUserVoucher} {...{ info, setInfo, visible, setVisible, needRefresh }} sendCount={sendCount} /> : null}
          {customActiveTab === "2" ? <GiftPhoneNumList sendItem={sendUserVoucher} {...{ info, setInfo, visible, setVisible, needRefresh }} manyPeople={true} sendCount={sendCount} /> : null}
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default B2BVoucherGiftModal;
