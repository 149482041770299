
import InquiryDetailSection from "./InquiryDetailSection";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const InquiryDetail = () => {
  return (
    <MobileViewContainer>
      {/* <Navbar activeBar={5} /> */}
      <InquiryDetailSection />
    </MobileViewContainer>
  );
};

export default InquiryDetail;
