import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Collapse, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUserStore } from "../../store/store";
import AnnounceModal from "./NoticeModal";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { BsPerson } from "react-icons/bs";
import { changeCancelableUserConfirm, changeCancelableUserRead } from "../../api/services/auth.service";
import { getUserVoucherCount } from "../../api/services/voucher.service";
import { getAnnouncements, getAnnouncementRead } from "../../api/services/content.service";
import { packageService, getUserOrdersByDate } from "../../api/services/package.service";
import { useAuthContext } from "../AuthContext";
import moment from "moment";
import { userService } from "../../api/services/user.service";
import { getUserPreCoupons } from "../../api/services/coupon.service";

const MODAL_STACK = {
  RAIN_CANCEL: "RAIN_CANCEL",
  ANNOUNCE: "ANNOUNCE",
  VOUCHER: "VOUCHER",
  DEPOSIT: "DEPOSIT",
  KAKAO_CHANNEL_AGREE: "KAKAO_CHANNEL_AGREE",
  PRECOUPON: "PRECOUPON",
};

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo, isUserLogin, logout } = useAuthContext();

  const [user, setUser, site, setSite, setPermission, permission] = useUserStore((state) => [state.user, state.setUser, state.site, state.setSite, state.setPermission, state.permission]);

  const [rainCancelData, setRainCancelData] = useState(null);

  useEffect(() => {
    if (isUserLogin && userInfo) {
      setUser({
        ...userInfo,
        phone_num: userInfo.phone,
      });
      setPermission(userInfo.site_permission);
    }

  }, [isUserLogin, userInfo]);

  const onLogout = async (redirect_path) => {
    await logout();

    if (redirect_path) navigate(redirect_path);
    else if (location.pathname === "/") navigate(0);
    else navigate("/");
  };

  useEffect(() => {
    console.log("Navbar useEffect", location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const checkKakaoLoginStatus = useCallback(async (user, silent = false) => {
    try {
      const response = await userService.getKakaoChannels();
      const data = response.data;
      if (data.result) {
        setUser({ ...user, kakaoChannelLinked: data.result });
        return true;
      } else {
        if (!silent) stackModal(MODAL_STACK.KAKAO_CHANNEL_AGREE);
      }
    } catch (error) {
      console.log(error.response?.data);
    }
    return false;
  }, [setUser]);

  useEffect(() => {
    if (!user) return;

    const fetchUserData = async () => {
      if (user?.id) {
        let pathname = location.pathname;
        if (pathname === "/") pathname = "";

        try {
          await userService.getUserById(user.id, pathname);
        } catch (error) {
          // console.log("access record error", error);
        }
      }
    };
    fetchUserData();
  }, [user, location]);

  useEffect(() => {
    if (!user) return;

    setTodaysReservations([]);

    const fetchCancelData = async () => {
      try {
        const response = await userService.getCancelableUsers();
        const data = response.data;
        if (data?.result && data.result?.is_confirmed === false) {
          setRainCancelData(data.result);
          stackModal(MODAL_STACK.RAIN_CANCEL);
          await changeCancelableUserRead();
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCancelData();

    const fetchUncheckedData = async () => {
      try {
        const response = await getUserVoucherCount();
        const data = response.data;
        if (data["unchecked_count"] > 0) stackModal(MODAL_STACK.VOUCHER);
      } catch (error) {
        console.log(error);
      }
      return
    };
    fetchUncheckedData();

    const today = moment().format("YYYY-MM-DD");

    const fetchOrderData = async () => {
      try {
        const response = await getUserOrdersByDate(today);
        const data = await response.data;
        // console.log("user-order", data);

        if (data.results.length > 0) {
          const _reservs = data.results.map((order) => ({
            ...order,
            reserv_id: order.id,
          }));
          setTodaysReservations((prev) => [...prev, ..._reservs]);
        }
      } catch (e) {
        console.log(e);
      }
      return
    };
    fetchOrderData();

    const fetchReservationGiftData = async () => {
      try {
        const response = await packageService.getReservationGifts(user.phone, today);
        const data = await response.data;
        // console.log("user-order", data);

        if (data.length > 0) {
          setTodaysReservations((prev) => [...prev, ...data]);
        }
      } catch (e) {
        console.log(e);
      }
      return
    };
    fetchReservationGiftData();

    const fetchPrecouponData = async ({ pageParam = 1 }) => {
      try {
        const response = await getUserPreCoupons(pageParam);
        setPrecouponData(response.data.results);
        if (response.data.results && response.data.results.length > 0) {
          stackModal(MODAL_STACK.PRECOUPON);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPrecouponData({ pageParam: 1 });

    // console.log("KakaoChannelCheck: Checking KakaoTalk channel status " + (accessToken ? "token_true " : "token_null ") + user?.kakaoChannelLinked);
    if (!user?.kakaoChannelLinked) {
      checkKakaoLoginStatus(user, false);
    }
  }, [user, checkKakaoLoginStatus]);

  // ======================================================================================================
  //    Modals settings
  // ======================================================================================================

  const [modalStack, setModalStack] = useState([]);
  const stackModal = (modal) => {
    setModalStack((prev) => {
      const _prev = [...prev];
      if (_prev.indexOf(modal) === -1) {
        _prev.push(modal);
      }
      return _prev;
    });
  };
  const closeModal = (modal) => {
    const _modalStack = [...modalStack];
    const idx = _modalStack.indexOf(modal);
    if (idx !== -1) {
      _modalStack.splice(idx, 1);
    }
    setModalStack(_modalStack);
  };
  const isModalOpened = (modal) => {
    if (!["", "/"].includes(location.pathname)) {
      return false;
    }
    return modalStack.length && modalStack[0] === modal;
  };

  useEffect(() => {
    if (modalStack.length > 0) {
      console.log("modalStack", modalStack);
    }
  }, [modalStack]);

  const [cancelChecked, setCancelChecked] = useState(false);

  const [announceDatas, setAnnounceDatas] = useState([]);

  const [isMo, setIsMobile] = useState(false);
  const [isAn, setIsAndroid] = useState(false);
  const [isIos, setIsIOS] = useState(false);

  const [todaysReservations, setTodaysReservations] = useState([]);
  const [depositReservId, setDepositReservId] = useState(null);

  const [precouponData, setPrecouponData] = useState(null);

  const changeCancelableUserConfirmHandler = async (isConfirm) => {
    try {
      await changeCancelableUserConfirm(isConfirm);
      console.log("confirm success");
      closeModal(MODAL_STACK.RAIN_CANCEL);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchAnnounceData = async () => {
      try {
        const response = await getAnnouncements();
        const data = response.data;

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let _link = element.link;

            if (isMobile) {
              if (isAndroid) {
                _link = element.android_link;
              } else if (isIOS) {
                _link = element.ios_link;
              }
            }


            if (!isUserLogin) {
              setAnnounceDatas((prev) => [...prev, { ...element, link: _link }]);
              stackModal(MODAL_STACK.ANNOUNCE + element.id);
            } else {
              const readResponse = await getAnnouncementRead(element.id);
              const _data = readResponse.data;

              if (_data.length === 0) {
                setAnnounceDatas((prev) => [...prev, { ...element, link: _link }]);
                stackModal(MODAL_STACK.ANNOUNCE + element.id);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAnnounceData();
    setIsMobile(isMobile);
    setIsAndroid(isAndroid);
    setIsIOS(isIOS);
  }, [isUserLogin]);

  useEffect(() => {
    closeModal(MODAL_STACK.DEPOSIT);

    for (let i = 0; i < todaysReservations.length; i++) {
      if (todaysReservations[i].gifted) {
        continue;
      }
      if (todaysReservations[i].reserv_state && todaysReservations[i].reserv_state !== "예약") {
        continue;
      }
      if (todaysReservations[i].state && todaysReservations[i].state !== "예약") {
        continue;
      }

      if (todaysReservations[i].depositRequired) {
        setDepositReservId(todaysReservations[i].reserv_id);
        stackModal(MODAL_STACK.DEPOSIT);
        break;
      }
    }
  }, [todaysReservations]);

  return (
    <React.Fragment>
      <div className={"sticky-top bg-white border-bottom py-1 px-2"}>
        <nav className={"navbar navbar-expand-lg navbar is-sticky"} id="navbar">
          <Container
            className="d-flex justify-content-between"
          // style={{ width: "calc(100% - 24px)"}}
          >
            <div className="d-flex justify-content-center">
              <Link className="navbar-brand nav-bar-color" to="/" style={{ fontFamily: "Jalnan" }}>
                {site?.name}
              </Link>
            </div>

            <div className="d-block">
              {/* {"user "} {JSON.stringify(user)} */}
              {!user ? (
                <Link to={`/login`} className="ms-3" style={{ cursor: "pointer" }}>
                  <BsPerson size="25" />
                </Link>
              ) : (
                <Link to="/my-page" className="" style={{ color: "#404040", lineHeight: "40px", fontFamily: "Gmarket-M" }}>
                  <BsPerson size="25" />
                </Link>
              )}
            </div>
          </Container>
        </nav>
      </div>

      <div id="kakao-talk-channel-chat-button" style={{ position: "fixed", right: "14px", bottom: "48px", zIndex: 999999 }}></div>

      {/* 
            // ======================================================================================================
            //    Modals
            // ====================================================================================================== 
            */}

      {/* 우천 취소 모달 확인 팝업 창. */}
      <Modal isOpen={!!isModalOpened(MODAL_STACK.RAIN_CANCEL)} toggle={() => closeModal(MODAL_STACK.RAIN_CANCEL)} centered>
        <ModalHeader toggle={() => closeModal(MODAL_STACK.RAIN_CANCEL)} className="border-0">
          <div className="d-flex justify-content-center"></div>
        </ModalHeader>
        <ModalBody className="text-center">
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingInline: 18 }}>
              <div className="d-flex justify-content-center">
                <i className="ri-alert-line ri-xl text-danger"></i>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p style={{ fontWeight: "bold" }}>{rainCancelData && rainCancelData.cancel_day} 우천 시 취소 안내</p>
              </div>

              {rainCancelData && rainCancelData.items.length && rainCancelData.items[0].description ? (
                <div className="mt-3" style={{ textAlign: "left" }} dangerouslySetInnerHTML={rainCancelData.items[0].description}>
                </div>
              ) : (
                <div className="mt-3" style={{ textAlign: "left" }}>
                  <p>안녕하세요, 고객님! 어메이징캠프는  우천시에도 정상운영합니다. </p>
                  <p>단, 우천으로 인해 취소를 원하시면 예약 당일 오전 10시 이전까지 마이페이지 구매내역에서 직접 취소하시면 100% 환불 가능합니다.</p>
                  <p>(예약당일 오전 10시 이후로는 예약 취소가 불가능하오니, 이 점 꼭 참고해 주시기 바랍니다.)</p>
                  <br />
                  <p>*어메이징캠프 환불정책</p>
                  <p>-이용일 3일전: 100% 자동환불<br />
                    -이용 이틀전 : 70% 환불(30%차감)<br />
                    -이용 하루전: 50% 환불<br />
                    -이용 당일 : 환불불가<br />
                    -당일 우천 시 이용가능하나, 취소 희망 시</p><br />

                  <p>본 메세지는 우천 시 발송되는 메시지입니다. </p>
                </div>
              )}
              <div className="mt-3">
                <input type="checkbox" id="cancelableCheck" onChange={(e) => setCancelChecked(true)} />
                <label htmlFor="cancelableCheck" className="ms-2">
                  위 내용을 확인하였습니다.
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex flex-row justify-content-end w-100 gap-3">
            <Button
              color="dark"
              onClick={() => {
                closeModal(MODAL_STACK.RAIN_CANCEL);
              }}
              className="rounded-pill"
            >
              닫기
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                if (cancelChecked) changeCancelableUserConfirmHandler(true);
                else alert("위 내용을 확인해주세요.");
              }}
              className="rounded-pill"
            >
              확인
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Voucher Modal */}
      <Modal isOpen={!!isModalOpened(MODAL_STACK.VOUCHER)} toggle={() => closeModal(MODAL_STACK.VOUCHER)} backdrop={"static"} id="staticBackdrop" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.VOUCHER);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="mt-4">
            <h5 className="modal-title mb-3" id="staticBackdropLabel">
              교환권이 도착했습니다
            </h5>
            <p className="text-muted mb-4">확인하시겠습니까?</p>
            <div className="hstack gap-2 justify-content-center">
              <Link to="/my-page/voucher" className="btn btn-success">
                <i className="healthicons-o-yes me-1 align-middle"></i>확인하러 가기
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Announce Modal */}
      {announceDatas.map((announce, idx) => {
        console.log(announce);
        console.log(MODAL_STACK.ANNOUNCE + announce.id);
        return (
          <AnnounceModal
            key={announce.id}
            visible={isModalOpened(MODAL_STACK.ANNOUNCE + announce.id)}
            toggle={() => closeModal(MODAL_STACK.ANNOUNCE + announce.id)}
            setVisible={() => closeModal(MODAL_STACK.ANNOUNCE + announce.id)}
            img={announce.image}
            id={announce.id}
            link={announce.link}
          />
        );
      })}

      {/* <div>{JSON.stringify(modalStack)}</div> */}

      {/* Deposit Modal */}
      <Modal isOpen={!!isModalOpened(MODAL_STACK.DEPOSIT)} toggle={() => closeModal(MODAL_STACK.DEPOSIT)} backdrop={"static"} id="staticBackdrop-deposit" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.DEPOSIT);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="">
            {todaysReservations
              .filter((reservation) => !!reservation.depositRequired && reservation.reserv_id === depositReservId)
              .map((reservation, idx) => {
                return (
                  <p key={idx}>
                    <span className="fw-bold">{reservation.name}</span> {reservation.reservationDate} {reservation.spot}번
                  </p>
                );
              })}
            <p>체크인 하기전에 청소보증금을 결제해 주세요</p>
            <p style={{ textAlign: "left" }}>
              <i className="ri ri-information-line label-icon align-middle fs-20"></i>&nbsp; 청소보증금이란?
              <br />
              <span style={{ color: "#666" }}>청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 결제됩니다. </span>
              시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.
              <br />
              <span style={{ color: "red" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</span>
            </p>
            <p className="text-muted mb-4">결제화면으로 이동하시겠습니까?</p>
            <div className="hstack gap-2 justify-content-center">
              <Link to={`/add-options-order?id=${depositReservId}&deposit=true`} state={{ tab: "3" }} className="btn btn-success">
                <i className="healthicons-o-yes me-1 align-middle"></i>결제하기
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={!!isModalOpened(MODAL_STACK.KAKAO_CHANNEL_AGREE)} toggle={() => closeModal(MODAL_STACK.KAKAO_CHANNEL_AGREE)} backdrop={"static"} id="staticBackdrop-deposit" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.KAKAO_CHANNEL_AGREE);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center px-5">
          <h4 className="text-center" style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>
            서비스 추가동의 안내
          </h4>
          <div className="pt-3 pb-2" style={{ textAlign: "left" }}>
            <h5 className="mb-2" style={{ fontFamily: "Gmarket-M" }}>
              안녕하세요, {user?.username}님
            </h5>
            <p className="my-2 fs-14">
              어메이징티켓에서 카카오 로그인을 이용한 고객님에게
              <br />더 나은 서비스를 제공하기 위해 고객님의 동의가 필요합니다.
            </p>
            <p className="my-3 fs-14">고객님의 정보는 아래의 혜택 제공을 위해 사용됩니다.</p>
            <p className="my-2 fs-14">* 맞춤 캠핑장 추천</p>
            <p className="my-2 fs-14">* 특별 할인 및 프로모션 정보</p>
            <p className="my-2 fs-14 mb-3">* 신상품 업데이트</p>
            <p className="my-2 fs-14">
              고객님의 정보는 안전하게 보호됩니다.
              <br />
              언제든 동의를 철회할 수 있습니다.
            </p>

            <p className="my-2 fs-14">동의하기를 누르시면 카카오 로그인으로 이동합니다.</p>
          </div>
          <div className="pt-3 pb-2"></div>
          <Row className="justify-content-center align-items-center">
            {/* <Col>
              <Button
                variant="light"
                className="w-100"
                onClick={() => {
                  onDismiss();
                  close();
                }}
              >
                하루동안 보지 않기
              </Button>
            </Col> */}
            <Col>
              <Button
                style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", borderColor: "#f6475f" }}
                variant="success"
                className="w-100 "
                onClick={() => {
                  closeModal(MODAL_STACK.KAKAO_CHANNEL_AGREE);
                  onLogout("/login");
                }}
              >
                동의하기
              </Button>
            </Col>
          </Row>
          <div className="pt-2"></div>
        </ModalBody>
      </Modal>

      {/* Precoupon Modal */}
      <Modal isOpen={!!isModalOpened(MODAL_STACK.PRECOUPON)} toggle={() => closeModal(MODAL_STACK.PRECOUPON)} backdrop={"static"} id="staticBackdrop-deposit" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.PRECOUPON);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="">
            <h5 className="modal-title mb-3" id="staticBackdropLabel">
              사용 가능한 예약권이 있습니다.
            </h5>
            <p className="text-muted mb-4">
              예약권은 마이페이지 - 예약권 - 예약권 사용하기 메뉴에서 사용 가능합니다.
            </p>
            <div className="vstack gap-2 px-3 mb-4">
              {precouponData && precouponData.length > 0 ? precouponData.slice(0, 1).map((item, idx) => {
                return (
                  <div key={item.id} className="d-flex align-items-center gap-2">
                    {item.image && <img src={item.image} alt={item.precoupon_name} style={{ width: "32px", height: "32px" }} />}
                    {item.precoupon_name}
                  </div>
                );
              }) : null}
            </div>
            <div className="hstack gap-2 justify-content-center">
              <Link to="/amazingpass" className="btn btn-primary text-white">
                사용하러 가기
              </Link>
              <Link to="/my-page/amazingpass" className="btn btn-white">
                확인하러 가기
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>


    </React.Fragment>
  );
};

function TempNavbar() {
  return null;
}

export default Navbar;
// export default TempNavbar;
