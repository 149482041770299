import Navbar from "../../../Components/Common/navbar";
import NoticeEditor from "./NoticeEditor";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const NoticeForm = () => {

    return (
        <MobileViewContainer>
            <Navbar />
            <NoticeEditor />
        </MobileViewContainer>
    );
}

export default NoticeForm;