import { AxiosResponse } from "axios";
import { API } from "../index";
import { SiteInfo, SitesInfo } from "@/api/type";

// Site Slogan
export const updateSiteSlogan = async (siteId: string, slogan: string) => {
  return API.put(`/sites/slogan/${siteId}/`, { slogan });
};

// Site Footer
export const updateSiteFooter = async (siteId: string, footer: string) => {
  return API.put(`/sites/footer/${siteId}/`, { footer });
};

// Site Location
export const updateSiteLocation = async (siteId: string, address: string) => {
  return API.put(`/sites/location/${siteId}/`, { address });
};

// Site SNS Links
export const updateSnsLink = async (id: string, url: string) => {
  return API.put(`/sites/link/${id}/`, { url });
};

export const deleteSnsLink = async (id: string) => {
  return API.delete(`/sites/link/${id}/`);
};

export const createSnsLink = async (siteId: string, sns: string, url: string) => {
  return API.post(`/sites/link/${siteId}/`, { url, sns });
};

export const siteService = {
  getSiteById: async (siteId: string): Promise<AxiosResponse<SiteInfo>> => {
    return await API.get(`/sites/camps/${siteId}/`);
  },

  getSitesApp: async (): Promise<AxiosResponse<SitesInfo>> => {
    return await API.get(`/sites/get_sites_app/`);
  },

  // Carousel Management
  uploadCarouselImage: async (formData: FormData): Promise<AxiosResponse> => {
    return await API.post("/sites/carousel-image-admin/", formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },

  deleteCarouselImage: async (id: number): Promise<AxiosResponse> => {
    return await API.delete(`/sites/carousel-image-admin/${id}`);
  },

  updateCarouselImage: async (id: number, displayLevel: number, carouselId: number): Promise<AxiosResponse> => {
    return await API.put(`/sites/carousel-image-admin/${id}/`, {
      display_level: displayLevel,
      carousel: carouselId,
    });
  },

  addCarousel: async (carouselData: any): Promise<AxiosResponse> => {
    return await API.post(`/sites/carousels/0/`, { carousel: carouselData });
  }
};