import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { API } from "../../../api";
import styled from "styled-components";
import { MdOutlineFileUpload } from "react-icons/md";
import heic2any from "heic2any";
import { fileService } from "../../../api/services/file.service";

const FileLabel = styled.label`
  background-color: #f6475f;
  color: #fff;
  text-align: center;
  padding: 8px 0;
  width: 100px;
  border-radius: 6px;
  cursor: pointer;
`;

const RealFileInput = styled.input.attrs({
  type: "file",
  id: "chooseFile",
  accept: "image/*,.heic,.heif",
})`
  display: none;
`;

function ImagesUploader({ groupKey, userId, onUploaded }) {
  const [previewImages, setPreviewImages] = useState([]);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchImages = useCallback((groupKey, updated = false) => {
    API.get(`/inquiry/images/?group_key=${groupKey}`)
      .then((response) => response.data)
      .then((data) => {
        setPreviewImages(data);
        if (updated) onUploaded(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [onUploaded]);

  const handleUpload = async (e) => {
    const file = e.currentTarget.files[0];
    let convertFile;

    if (file.type === "image/heic" || file.type === "image/heif") {
      const convertedImageBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      });
      convertFile = new File([convertedImageBlob], file.name + ".jpeg", {
        type: convertedImageBlob.type,
      });
    }

    setLoading(true);
    fileService.uploadFile({
      url: `/inquiry/images/`,
      groupKey,
      userId,
      file: file.type === "image/heic" || file.type === "image/heif" ? convertFile : file,
      name: file.type === "image/heic" || file.type === "image/heif" ? file.name + ".jpeg" : file.name,
      onUploadProgress: (percentCompleted) => {
        setImageUploadProgress(percentCompleted);
      },
    })
      .then((result) => {
        console.log(result);
        fetchImages(groupKey, true);
        e.currentTarget.value = "";
      })
      .catch((error) => {
        console.warn(error);
      }).finally(() => {
        setLoading(false);
        setImageUploadProgress(0);
      });
  };

  const handleDelete = (imageId) => {
    API.delete(`/inquiry/images/${imageId}/`)
      .then((result) => {
        fetchImages(groupKey, true);
        console.log(result);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  useEffect(() => {
    fetchImages(groupKey);
  }, [groupKey, fetchImages]);

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={12} className="mb-3">
          <div className="d-flex flex-direction-row gap-3 flex-wrap">
            {previewImages
              ? previewImages.map((_image) => (
                <div key={_image.id}>
                  <div>
                    <img
                      src={_image.image}
                      alt={_image.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(_image.id)}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              ))
              : null}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={12}>
          <FileLabel htmlFor="chooseFile">
            <MdOutlineFileUpload />
            사진 첨부
          </FileLabel>
          <RealFileInput onChange={handleUpload} />
        </Col>
        <Col lg={12}>
          {loading ? (
            <div>
              <progress value={imageUploadProgress} max="100">
                {imageUploadProgress}%
              </progress>
            </div>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ImagesUploader;
