import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Common/navbar";
import styled from "styled-components";
import { API, BACKEND_HOST } from "../../../api";
import { Button, Col } from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../Components/AuthContext";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const VoucherWrapper = styled.div`
  width: 90%;
  min-height: 176px;
  position: relative;
  background-color: #fff;
  border: 1px solid #f6475f;
  padding: 16px 16px 20px 20px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #f6475f;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #fff0f2;
  }
`;

const VoucherContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const PromotionB2BVoucherPage = () => {
    const location = useLocation();

    const { isUserLogin } = useAuthContext();
    const [voucherItem, setVoucherItem] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [download, setDownload] = useState(false);
    const { share_pin_code } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        fetchItem();
    }, [isUserLogin, share_pin_code]);

    const fetchItem = () => {
        setIsLoading(true);
        API.get(`${BACKEND_HOST}/voucher/stock/check/${share_pin_code}/`)
            .then((response) => response.data)
            .then((data) => {
                console.log("get vouchers", data);
                setVoucherItem(data);
            })
            .catch((e) => {
                console.log(e);
                setError(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    return (
        <MobileViewContainer>
            <Navbar />

            {!isUserLogin ? (
                <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
                        <span style={{ color: "#ff5a5f" }}>프로모션</span>
                        <br />
                        쿠폰
                    </h2>
                    <VoucherWrapper>
                        <VoucherContent>

                            <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", opacity: 0.5 }}>
                                {voucherItem?.image ? <div style={{ marginRight: "14px" }}>
                                    <img src={voucherItem.image} alt="Voucher" style={{ width: "100%", marginBottom: "10px", maxWidth: "96px" }} />
                                </div> : null}
                                <div>
                                    <p style={{ fontWeight: "600", fontSize: "20px" }}>{voucherItem?.voucher_name}</p>
                                    {/* <p style={{ marginTop: "10px", fontSize: "20px", color: "#f64757" }}>
                                            {voucherItem?.discount_type === "price" ? `${voucherItem?.discount_amount}원 할인` : `${voucherItem?.discount_rate}% 할인`}
                                        </p> */}
                                </div>
                            </Col>

                            <Col>
                                <Button
                                    style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", float: "right" }}
                                    onClick={() => {
                                        navigate(`/login?path=${location.pathname}`);
                                    }}
                                >
                                    로그인 하기
                                </Button>
                            </Col>
                        </VoucherContent>
                    </VoucherWrapper>
                </div>
            ) : (
                <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    {!isLoading && voucherItem && voucherItem !== null ? (
                        <>
                            <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
                                <span style={{ color: "#ff5a5f" }}>프로모션</span>
                                <br />
                                쿠폰
                            </h2>

                            <div style={{ width: "100%", paddingInline: "32px", marginBottom: "12px" }}>
                                <span style={{ fontWeight: "600", fontSize: "16px" }}>{voucherItem?.username} </span>
                                <span>님이 선물을 보냈습니다.</span>
                            </div>

                            <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">

                                {voucherItem?.remainings > 0 ? Array.from({ length: voucherItem?.remainings }).map((_, index) => (
                                    <VoucherWrapper key={index}>
                                        <VoucherContent>

                                            <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                {voucherItem?.image ? <div style={{ marginRight: "14px" }}>
                                                    <img src={voucherItem.image} alt="Voucher" style={{ width: "100%", marginBottom: "10px", maxWidth: "96px" }} />
                                                </div> : null}
                                                <div>
                                                    <p style={{ fontWeight: "600", fontSize: "20px" }}>{voucherItem?.voucher_name}</p>
                                                    {/* <p style={{ marginTop: "10px", fontSize: "20px", color: "#f64757" }}>
                                                            {voucherItem?.discount_type === "price" ? `${voucherItem?.discount_amount}원 할인` : `${voucherItem?.discount_rate}% 할인`}
                                                        </p> */}
                                                    {/* <p style={{ marginTop: "10px", color: "#999" }}>만료일: {voucherItem?.expire_date}</p> */}
                                                </div>
                                            </Col>

                                            <Button
                                                style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", opacity: download ? 0.5 : 1, float: "right" }}
                                                onClick={() => {
                                                    API.put(`/voucher/stock/receive/${share_pin_code}/`, {}).then((response) => {
                                                        console.log("receive-voucher-gift", response);
                                                        alert("선물을 받았습니다.");
                                                    }).catch((e) => {
                                                        console.log(e);
                                                        alert("선물을 받을 수 없습니다.");
                                                    }).finally(() => {
                                                        fetchItem();
                                                    });
                                                }}
                                            >
                                                선물받기
                                            </Button>
                                        </VoucherContent>
                                    </VoucherWrapper>
                                )) : (
                                    <VoucherWrapper>
                                        <VoucherContent>
                                            <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", opacity: 0.5 }}>
                                                {voucherItem?.image ? <div style={{ marginRight: "14px" }}>
                                                    <img src={voucherItem.image} alt="Voucher" style={{ width: "100%", marginBottom: "10px", maxWidth: "96px" }} />
                                                </div> : null}
                                                <div>
                                                    <p style={{ fontWeight: "600", fontSize: "20px" }}>{voucherItem?.voucher_name}</p>
                                                    {/* <p style={{ marginTop: "10px", fontSize: "20px", color: "#f64757" }}>
                                                            {voucherItem?.discount_type === "price" ? `${voucherItem?.discount_amount}원 할인` : `${voucherItem?.discount_rate}% 할인`}
                                                        </p> */}
                                                </div>
                                            </Col>
                                            <p style={{ fontSize: "18px", color: "#999", float: "right" }}>이미 선물을 받았습니다.</p>
                                        </VoucherContent>
                                    </VoucherWrapper>
                                )}
                            </div>

                            <div style={{ textAlign: "start", width: "90%", margin: "20px auto", lineHeight: "28px", color: "#000" }}>
                                <p style={{ fontWeight: "600" }}>사용가능한 사이트</p>
                                <div>
                                    {/* {voucherItem?.site_names?.map((site, index) => (<div key={site}>
                                            <span>{site}</span>
                                        </div>))} */}
                                    {voucherItem?.site_name}
                                </div>
                            </div>

                            <div style={{ textAlign: "start", width: "90%", margin: "30px auto", lineHeight: "28px", color: "#000" }}>
                                <p style={{ fontWeight: "600" }}>유의사항</p>
                                <div dangerouslySetInnerHTML={{ __html: voucherItem?.precautions }}></div>
                            </div>
                        </>
                    ) : isLoading ? (
                        <div>로딩중...</div>
                    ) : (
                        <div>정보를 찾을 수 없습니다.</div>
                    )}
                </div>
            )}
        </MobileViewContainer>
    );
};

export default PromotionB2BVoucherPage;
