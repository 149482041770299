import InquiryFormSection from "./InquiryFormSection";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const InquiryForm = () => {
  return (
    <MobileViewContainer>
      <InquiryFormSection />
    </MobileViewContainer>
  );
};

export default InquiryForm;
