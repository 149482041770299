import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { updateAccessToken } from "../../api";
import { authService } from "../../api/services/auth.service";
import MobileViewContainer from "../../Components/MobileViewContainer";

const PhoneLogin = () => {
  const [phoneNum, setPhoneNum] = React.useState("");
  const [authNum, setAuthNum] = React.useState("");
  const [responseAuthNum, setResponseAuthNum] = React.useState(null);
  const [isAuth, setIsAuth] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleInputPhoneChange = (event) => {
    setPhoneNum(event.target.value);
  };

  const handleInputAuthChange = (event) => {
    setAuthNum(event.target.value);
  };

  const handleButtonClick = () => {
    if (responseAuthNum === null || isAuth === false) {
      authService.postPhoneAuth(phoneNum)
        .then((response) => {
          setResponseAuthNum(response.data["auth_num"]);
          setIsAuth(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (responseAuthNum !== null && responseAuthNum === authNum) {
      authService.postPhoneLogin(phoneNum)
        .then((response) => {
          window.localStorage.setItem("refresh_token", response.data["refresh_token"]);
          updateAccessToken(response.data["access_token"]);

          const state = location.state;
          if (state && state.from) {
            navigate(state.from);
          } else {
            navigate("/");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("인증번호가 일치하지 않습니다.");
    }
  };

  return (
    <React.Fragment>
      <MobileViewContainer>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={`/amazing-logo.png`} alt="" height="120" />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card className="bg-white rounded shadow-2">
                    <CardBody>
                      <h4 className="text-center">휴대폰 본인인증</h4>

                      <div className="mb-4 mt-5">
                        <label htmlFor="phone" className="form-label">
                          휴대폰 번호
                        </label>
                        <div className="d-flex flex-row">
                          <input
                            type="text"
                            maxLength={11}
                            className="form-control"
                            placeholder="휴대폰 번호를 입력해주세요"
                            onChange={handleInputPhoneChange}
                          />
                          <Button
                            onClick={handleButtonClick}
                            color="success"
                            className="ms-3 btn"
                          >
                            인증하기
                          </Button>
                        </div>
                      </div>

                      {isAuth && (
                        <div className="mb-4">
                          <label htmlFor="auth" className="form-label">
                            인증번호
                          </label>
                          <div className="d-flex flex-row">
                            <input
                              type="text"
                              maxLength={6}
                              className="form-control"
                              placeholder="인증번호를 입력해주세요"
                              onChange={handleInputAuthChange}
                            />
                            <Button
                              onClick={handleButtonClick}
                              color="success"
                              className="ms-3 btn"
                            >
                              확인하기
                            </Button>
                          </div>
                        </div>
                      )}
                      <div className="p-2">
                        <Link to="/" className="text-muted">
                          <i className="mdi mdi-account-circle"></i> 메인으로 이동하기
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </MobileViewContainer>
    </React.Fragment>
  );
};

export default PhoneLogin;
