import React, { useState } from "react";
import Navbar from "../../Components/Common/navbar";
import { Container, Col, Row, Card, CardBody, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import { API, BACKEND_HOST } from "../../api";
import NavBottomBar from "../../Components/Common/NavBottomBar";
import { SUBDOMAIN } from "../../assets/meta/meta";
import MobileViewContainer from "../../Components/MobileViewContainer";

const ShopList = () => {
  const navigate = useNavigate();

  const [products, setProducts] = React.useState([]);

  const [productQuantity, setProductQuantity] = useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const is_test = SUBDOMAIN === "test" ? true : false;
        const query = is_test ? "?is_test=true" : "";

        const response = await API.get(`/shop/product/${query}`);
        const data = response.data;

        console.log("get shop products", data);
        setProducts(data);

        const productQuantity = data.map((item) => ({
          id: item.id,
          quantity: 0,
        }));
        setProductQuantity(productQuantity);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const product_purchase_count = productQuantity
    .map((item) => {
      return item.quantity;
    })
    .reduce((prev, curr, obj) => {
      return prev + curr;
    }, 0);

  return (
    <MobileViewContainer>
      <Navbar activeBar={3} />

      <Container>
        <Row className="mt-4 d-flex flex-wrap" style={{ paddingBottom: "160px" }}>
          {products.map((item, idx) => {
            return (
              <Col key={idx} xxl={6} xl={6} md={6} sm={6} xs={6}>
                <Card className="">
                  <div
                    onClick={() => {
                      navigate(`/shop/${item.id}`, { state: { product: { ...item, quantity: productQuantity[idx].quantity } } });
                    }}
                  >
                    <img
                      className="card-img-top img-fluid"
                      src={`${item.thumbnail}`}
                      alt="Card cap"
                      style={{ objectFit: "cover", maxWidth: "265px", margin: "0 auto", display: "block", borderRadius: "10px", maxHeight: "265px" }}
                    />
                  </div>
                  <CardBody className="d-flex flex-column justify-content-center">
                    <h5 style={{ fontFamily: "Gmarket-M", fontWeight: "600", marginBottom: "3px" }}>{item.name}</h5>
                    {/* {item.remaining_stock != 9999 && item.remaining_stock > 0 ? <div style={{ fontFamily: "Gmarket-M", fontSize: "13px" }}>(남은 재고 {item.remaining_stock}개)</div> : null} */}
                    {item.no_sale_price > item.price && item.sale_rate > 0 ? (
                      <div className="mb-1">
                        <span style={{ fontFamily: "Gmarket-M", fontSize: "13px", textDecoration: "line-through", color: "#6d6d6d" }}> {new Intl.NumberFormat().format(item.no_sale_price)} 원</span>
                        <span style={{ fontFamily: "Gmarket-B", fontSize: "15px", color: "#f6475f", marginLeft: "6px" }}>{item.sale_rate}%</span>
                      </div>
                    ) : null}
                    <span className="card-text mt-0 mb-1" style={{ fontFamily: "Gmarket-M" }}>
                      {new Intl.NumberFormat().format(item.price)} 원
                    </span>
                    {/* {item.variations.length <= 0 && item.remaining_stock > 0 ? (
                        <QuantityButtonInput
                          quantity={productQuantity[idx].quantity}
                          onChange={(value) => {
                            if (value > item.remaining_stock) {
                              alert("구매 가능 개수를 초과하였습니다.");
                              return;
                            }
                            const _productQuantity = [...productQuantity];
                            _productQuantity[idx].quantity = value;
                            setProductQuantity(_productQuantity);
                          }}
                        />
                      ) : null} */}

                    {item.remaining_stock <= 0 ? <span style={{ fontFamily: "Gmarket-M", color: "#f6475f", fontSize: "15px" }}>품절</span> : null}

                    {/* <div> 상태: {item.state}</div> */}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
        {/* <Row
            className="position-fixed d-flex flex-row justify-content-center"
            style={{ maxWidth: "620px", bottom: "100px", left: "50%", width: "95%", transform: "translate(-50%)", zIndex: 999, margin: "0 auto" }}
          >
            <Button
              disabled={!product_purchase_count}
              style={{ border: "none", color: "white", background: "#F6475F", width: "100%", borderRadius: "12px", fontSize: "16px", padding: "12px", fontWeight: "bold" }}
              onClick={() => {
                navigate(`/order-shop`, {
                  state: {
                    products: products.map((item, idx) => ({ ...item, quantity: productQuantity[idx].quantity })).filter((item) => !!item.quantity),
                  },
                });
              }}
            >
              총 {product_purchase_count}개 바로 결제하기
            </Button>
          </Row> */}
      </Container>

      <NavBottomBar activeBar={4} />
    </MobileViewContainer>
  );
};

export default ShopList;
