import React from "react";
import B2BInfoImg from "../../../assets/images/b2b-info.jpg";
import Navbar from "../../../Components/Common/navbar";
import MobileViewContainer from "../../../Components/MobileViewContainer";

export default function B2BInfo() {
  return (
    <MobileViewContainer>
      <Navbar />
      <img src={B2BInfoImg} alt="banner" style={{ objectFit: "cover", width: "100%" }} />
    </MobileViewContainer>
  );
}
