import { AxiosResponse } from "axios";
import { API } from "../index";
import { Page } from "../type";

export const ticketService = {
  getTicketListByPage: async (pageData: Page): Promise<AxiosResponse> => {
    return await API.get(`/ticket/user-ticket-app/?page=${pageData.page}${pageData.queryString}`);
  },

  postTicketUseById: async (id: number): Promise<AxiosResponse> => {
    return await API.post(`/ticket/use-ticket/`, { id });
  },

  requestTicketCancel: async (ticketId: number): Promise<AxiosResponse> => {
    return await API.post(`/ticket/cancel/${ticketId}/`);
  },

  putReceiveTicketGiftById: async (giftId: number): Promise<AxiosResponse> => {
    return await API.put(`/ticket/receive-ticket-gift/${giftId}/`);
  },

  getTicketGiftByRecipientPhone: async (phoneNum: number): Promise<AxiosResponse> => {
    return await API.get(`/ticket/ticket-gift/?recipient=${phoneNum}`);
  },

  getTicketSendDataByPage: async (pageData: Page): Promise<AxiosResponse> => {
    return await API.get(`/ticket/user-ticket-app/?page=${pageData.page}${pageData.queryString}`);
  },

  deleteTicketGiftById: async (ticketId: number): Promise<AxiosResponse> => {
    return await API.delete(`/ticket/cancel-ticket-gift/${ticketId}/`);
  },

  getTicketProductById: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/ticket/products/${id}/`);
  },

  getTicketProducts: async (): Promise<AxiosResponse> => {
    return await API.get(`/ticket/products/`);
  }
};