import { API } from '../index';

// Carousel Management
export const getCarouselById = async (id: string) => {
  return API.get(`/sites/carousel/${id}/`);
};

export const updateCarouselImage = async (key: string, display_level: number, carouselId: string) => {
  return API.put(`/sites/carousel-image/${key}/`, {
    display_level,
    carousel: carouselId
  });
};

export const deleteCarouselImage = async (key: string) => {
  return API.delete(`/sites/carousel-image/${key}/`);
};

export const uploadCarouselImage = async (formData: FormData) => {
  return API.post('/sites/carousel-image/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateCarouselOrder = async (carousels: any) => {
  return API.put('/sites/carousels/update-order/', carousels);
};

export const addCarousel = async (images: string[]) => {
  return API.post('/sites/carousels/', { images });
};

export const deleteCarousel = async (id: string) => {
  return API.delete(`/sites/carousels/${id}/`);
};