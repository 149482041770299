import React, { useEffect } from "react";

import _default from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";

import Navbar from "../../Components/Common/navbar";
import PackageDetailSection from "./packageDetailSection";
import { useParams, useSearchParams } from "react-router-dom";
import { getNextFriday, getNextSaturday } from "../../common/utils/dateUtil";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import MobileViewContainer from "../../Components/MobileViewContainer";

export function LodgingPackageDetailPage() {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [startDate, setStartDate] = React.useState(moment(getNextFriday()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = React.useState(moment(getNextSaturday()).format("YYYY-MM-DD"));

    const handleDate = (dates) => {
        if (dates.length < 2) return ["", ""];
        if (!(dates[0] instanceof Date) || !(dates[1] instanceof Date)) return ["", ""];

        const startDate = moment(dates[0]).format("YYYY-MM-DD");
        const endDate = moment(dates[1]).format("YYYY-MM-DD");
        console.log("startDate", startDate);
        console.log("endDate", endDate);
        setStartDate(startDate);
        setEndDate(endDate);

        setSearchParams({ "start-date": startDate, "end-date": endDate });

        return [startDate, endDate];
    }

    useEffect(() => {
        if (searchParams.get("start-date") && searchParams.get("end-date")) {
            setStartDate(searchParams.get("start-date"));
            setEndDate(searchParams.get("end-date"));
        }
    }, [searchParams["start-date"], searchParams["end-date"]]);

    if (!id) return null;

    return (
        <MobileViewContainer>
            <Navbar activeBar={6} />

            <div className="back-to-list mt-4 px-2">
                <Link to="/lodging-package" className="">
                    <i className="ri-arrow-left-line"></i> 목록으로
                </Link>
            </div>

            <Container className="justify-content-center" style={{ minHeight: "100vh" }}>

                <div className="pt-4">
                    <Flatpickr
                        key="2"
                        value={[startDate, endDate]}
                        onChange={handleDate}
                        className="form-control fs-17"
                        options={{
                            locale: _default.ko,
                            dateFormat: "Y-m-d",
                            minDate: "today",
                            defaultDate: [startDate, endDate],
                            mode: "range",
                        }}
                    />
                </div>

                <PackageDetailSection
                    id={id} startDate={startDate} endDate={endDate}
                />

            </Container>
        </MobileViewContainer>
    );
}