import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Col,
  Row,
  Button,
} from "reactstrap";

import ReservationGiftUseModal from "../../../../Components/modal/ReservationGiftUseModal";
import jwtDecode from "jwt-decode";
import ReservationCheckoutModal from "../../../../Components/modal/ReservationCheckoutModal";
import { getAccessToken } from "../../../../api/auth";
import {userService} from "../../../../api/services/user.service";
import { packageService } from "../../../../api/services/package.service";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid rgb(102, 151, 249);
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const ReservationGift = (props) => {
  const navigate = useNavigate();

  const [items, setitems] = React.useState([]);
  const [modalData, setModalData] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [checkoutModal, setCheckoutModal] = React.useState(false);

  function tog_backdrop() {
    setModal(!modal);
  }

  React.useEffect(() => {
    setdata();
  }, [props]);

  const setdata = async () => {
    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        let decoded = {};
        try {
          decoded = jwtDecode(accessToken);
        } catch (e) {
          alert("got error decode", accessToken);
        }

        const userId = decoded["user_id"];
        const userResponse = await userService.getUserById(userId);

        const userData = userResponse.data;
        let phone_num = "";
        if (!!userData["전화번호"]) {
          phone_num = userData["전화번호"];
        }

        const reservResponse = await packageService.getReservationGifts(phone_num);

        const reservData = reservResponse.data;
        let _reservs = reservData.results;
        _reservs = _reservs.sort((a, b) => {
          return a.reserv_state === "예약" &&
            (a.gift_state === "선물수신" || a.gift_state === "선물발신")
            ? -1
            : +1;
        });

        setitems(reservData.results);
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  const handelReceiveGift = async (giftId) => {
    try {
      if (window.confirm("선물을 수령하시겠습니까?")) {
        const response = await packageService.putReceiveReservationById(giftId);
        const data = response.data;
        console.log(data);
        setdata();
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center">
          {items.length !== 0 ? (
            items.map((item, idx) => {
              return (
                // {
                //     "id": 11,
                //     "name": "테이블이용권",
                //     "spot": "2",
                //     "sender": "이기환",
                //     "phone_num": "01073032625",
                //     "reserv_id": "2977",
                //     "reservationDate": "2023-02-14",
                //     "reserv_state": "예약",
                //     "gift_state": "선물발신",
                //     "send_date": "2022-12-24T11:53:20.743478"
                //   }
                <Col
                  lg={12}
                  xxl={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={idx}
                  className="mx-3 my-2 rounded-3"
                >
                  {["예약", "입장", "퇴장", "외출"].includes(
                    item.reserv_state
                  ) ? (
                    <Item
                      visible={true}
                      onClick={() => {
                        // if (item.gift_state === "선물수신") {
                        //     setModalData(item); tog_backdrop();
                        // }
                      }}
                      className="bg-white"
                    >
                      <div className="d-flex justify-content-between px-2 border-bottom">
                        <div className="card-title pt-3">{item.name}</div>
                        {item.gift_state === "선물수신" ? null : (
                          <p className="text-muted pt-3">{item.gift_state}</p>
                        )}
                      </div>
                      <div className="d-flex py-3">
                        <div className="avatar-md mx-2">
                          <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="mt-1">
                          {/* <p className="card-text text-muted">GIFT ID : {item.id} </p> */}
                          <p className="card-text text-muted">
                            보낸이 : {item.sender}{" "}
                          </p>

                          {item.reservationEndDate &&
                          item.reservationDate === item.reservationEndDate ? (
                            <p className="card-text text-muted">
                              예약일 : {item.reservationDate}
                            </p>
                          ) : (
                            <p className="card-text text-muted">
                              예약일 : {item.reservationDate} ~{" "}
                              {item.reservationEndDate}
                            </p>
                          )}

                          <p className="card-text text-muted">
                            자리 : {item.spot}{" "}
                          </p>
                          <p className="card-text text-muted">
                            상태 : {item.reserv_state}{" "}
                          </p>
                          <div>
                            {item.gift_state === "선물발신" ? (
                              <Button
                                color="success"
                                onClick={(e) => {
                                  handelReceiveGift(item.id);
                                }}
                              >
                                선물받기
                              </Button>
                            ) : null}
                            {item.gift_state === "선물수신" &&
                            item.reserv_state === "예약" ? (
                              <Button
                                color="primary"
                                onClick={(e) => {
                                  setModalData(item);
                                  tog_backdrop();
                                }}
                              >
                                {item.depositRequired
                                  ? "체크인/청소보증금"
                                  : "체크인"}
                              </Button>
                            ) : null}
                            {["선물사용", "선물수신"].includes(
                              item.gift_state
                            ) && item.reserv_state === "입장" ? (
                              // <Button
                              //   color="primary"
                              //   onClick={(e) => {
                              //     setModalData(item);
                              //     setCheckoutModal(true);
                              //   }}
                              // >
                              //   체크아웃
                              // </Button>
                              <span>입장</span>
                            ) : null}
                            {["선물사용", "선물수신"].includes(
                              item.gift_state
                            ) && item.reserv_state === "퇴장" ? (
                              <Button color="primary" disabled>
                                체크아웃 대기중
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Item>
                  ) : (
                    <Item visible={false} className="bg-white">
                      <div className="d-flex justify-content-between px-2 border-bottom">
                        <div className="card-title pt-3">{item.name}</div>
                        <p className="text-muted pt-3">사용불가</p>
                      </div>
                      <div className="d-flex py-3">
                        <div className="avatar-md mx-2">
                          <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="card-text text-muted">
                            보낸이 : {item.sender}{" "}
                          </p>

                          {item.reservationEndDate &&
                          item.reservationDate === item.reservationEndDate ? (
                            <p className="card-text text-muted">
                              예약일 : {item.reservationDate}
                            </p>
                          ) : (
                            <p className="card-text text-muted">
                              예약일 : {item.reservationDate} ~{" "}
                              {item.reservationEndDate}
                            </p>
                          )}

                          <p className="card-text text-muted">
                            자리 : {item.spot}{" "}
                          </p>
                          <p className="card-text text-muted">
                            상태 : {item.reserv_state}{" "}
                          </p>
                        </div>
                      </div>
                    </Item>
                  )}
                </Col>
              );
            })
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ alignItems: "center", height: 150 }}
            >
              선물함이 비었습니다.
            </div>
          )}
        </Col>
      </Row>

      <ReservationGiftUseModal
        setVisible={setModal}
        visible={modal}
        info={modalData}
        setInfo={setModalData}
        navigate={navigate}
        needRefresh={setdata}
      />

      <ReservationCheckoutModal
        setVisible={setCheckoutModal}
        visible={checkoutModal}
        info={{ ...modalData, ...{ state: modalData.reserv_state } }}
        setInfo={setModalData}
        navigate={navigate}
        needRefresh={setdata}
      />
    </React.Fragment>
  );
};
export default ReservationGift;
