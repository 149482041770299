import React from "react";

import Navbar from "../../Components/Common/navbar";
import NavBottomBar from "../../Components/Common/NavBottomBar";
import LodgingPackageCalendar from "./calendar";
import MobileViewContainer from "../../Components/MobileViewContainer";

const LodgingPackagePage = () => {

    return (
        <MobileViewContainer>
            <Navbar activeBar={6} />

            <LodgingPackageCalendar />

            <NavBottomBar activeBar={6} />
        </MobileViewContainer>
    );
};

export default LodgingPackagePage;
