import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Alert, Spinner } from "reactstrap";

import { REST_API_KEY } from "../../common/social/kakao/api"
import { authService } from "../../api/services/auth.service";
import MobileViewContainer from "../../Components/MobileViewContainer";
import { useAuthContext } from "../../Components/AuthContext";

const KakaoPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const { loginSocial } = useAuthContext();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const KAKAO_CODE = params.get('code');
    const state = params.get('state');

    if (!KAKAO_CODE || !state) {
      console.error("Failed to parse KAKAO_CODE or state from URL");
      setError("URL 파싱에 실패했습니다. 다시 시도해주세요.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    authService.exchangeKakaoCode(KAKAO_CODE, REST_API_KEY)
      .then((response) => {
        if (!response.data?.access_token) {
          setError("카카오 로그인에 실패했습니다. 다시 시도해주세요.");
          return;
        }
        return loginSocial("kakao", response.data?.access_token);
      })
      .then(() => {
        const redirectTo = window.localStorage.getItem("redirectTo");
        if (redirectTo) {
          window.localStorage.removeItem("redirectTo");
          navigate(`${redirectTo}`);
        } else navigate(`/`);
      })
      .catch((error) => {
        console.log(error);
        setError("카카오 로그인에 실패했습니다. 다시 시도해주세요.");
      }).finally(() => {
        setIsLoading(false);
      });
  }, [location, navigate]);

  return (
    <React.Fragment>
      <MobileViewContainer>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={`/amazing-logo.png`} alt="" height="120" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card className="bg-white rounded shadow-2">
                  <CardBody>
                    <h4 className="text-center">카카오 로그인</h4>

                    <div className="mb-4 mt-5 ">

                      {isLoading && (
                        <div className="p-2">
                          <div className="text-center">
                            <Spinner color="primary" />
                          </div>
                          <div className="text-center">
                            <p>
                              로그인을 처리중입니다. 잠시만 기다려주세요.
                            </p>
                          </div>
                        </div>
                      )}

                      {!isLoading && error && (
                        <div className="text-center">
                          <Alert color="danger">{error}</Alert>
                        </div>
                      )}
                    </div>
                    <div className="p-2">
                      <Link to="/login" className="text-muted">
                        <i className="mdi mdi-account-circle"></i> 로그인 화면으로 돌아가기
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </MobileViewContainer>
    </React.Fragment>
  );
};

export default KakaoPage;
