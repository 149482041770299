import { AxiosResponse } from "axios";
import { API } from "../index";

export const noticeService = {
  postAnnounceRead: async (userId: number, announcementId: number): Promise<AxiosResponse> => {
    return await API.post("/notice/announce-read/", {
      user: userId,
      announcement: announcementId,
    });
  },

  deleteNoticeById: async (noticeId: string): Promise<AxiosResponse> => {
    return await API.delete(`/notice/content/${noticeId}/`);
  }
};