import { AxiosResponse } from "axios";
import { API } from "../index";
import { Page } from "../type";

export const packageService = {
  getPackageItemById: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/package/items/${id}/`);
  },

  getPackageItemsByDate: async (startDate: string, id: string): Promise<AxiosResponse> => {
    return await API.get(`/package/items/?date=${startDate}&id=${id}`);
  },

  getLodgePackageDetail: async (id: string, startDate: string, endDate: string): Promise<AxiosResponse> => {
    return await API.get(`/package/lodging/${id}/?start_date=${startDate}&end_date=${endDate}`);
  },

  getPackagesByDateRange: async (startDate: string, endDate: string, subdomain: string, membership_product_id?: string): Promise<AxiosResponse> => {
    return await API.get(`/package/?start_date=${startDate}&end_date=${endDate}&subdomain=${subdomain}${membership_product_id ? `&membership_product_id=${membership_product_id}` : ""}`);
  },

  getLodgePackagePrice: async (id: string, startDate: string, endDate: string, spot_str: string): Promise<AxiosResponse> => {
    return await API.get(`/package/get-lodge-package-price/?package_id=${id}&start_date=${startDate}&end_date=${endDate}&spots=${spot_str}`);
  },

  getReservationById: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/package/get-reservation/${id}/`);
  },

  getOptionListByReservId: async (reservId: string): Promise<AxiosResponse> => {
    return await API.get(`/package/option-list/?reserv_id=${reservId}`);
  },

  getOptionListByOrderId: async (orderId: string): Promise<AxiosResponse> => {
    return await API.get(`/package/option-list/?order_id=${orderId}`);
  },

  inviteUserForReservation: async (reservId: number, phoneNum: string): Promise<any> => {
    const phone_num = phoneNum.replaceAll("-", "");
    const result = await API.post(
      `/package/invite-user-for-gift/${reservId}/`,
      {
        reservId: reservId,
        phoneNum: phone_num,
      }
    );
    return result.data;
  },

  giftReservationToKakaoFriend: async (reservId: number, kakaoId: string): Promise<any> => {
    const result = await API.post(
      `/package/send-reservation-gift-kakao/${reservId}/`,
      {
        reservId: reservId,
        kakaoId: kakaoId,
      }
    );
    return result.data;
  },

  giftReservationByUrl: async (reservId: number): Promise<any> => {
    const result = await API.post(
      `/package/send-reservation-gift-url/`,
      {
        reservationId: reservId,
      }
    );
    return result.data;
  },

  useReservationGift: async (reservId: number): Promise<any> => {
    const result = await API.post(
      `/package/use-reservation-gift/${reservId}/`
    );
    return result.data;
  },

  getReservationQRCode: async (reservId: number): Promise<any> => {
    const result = await API.get(
      `/package/reservation-qr/${reservId}/`
    );
    return result.data;
  },

  getReservationGifts: async (phone: string, date?: string): Promise<AxiosResponse> => {
    return await API.get(`/package/reservation-gift/`, {
      params: {
        // ?recipient=${phone}&date=${date}
        recipient: phone,
        date: date
      }
    });
  },

  putReceiveReservationById: async (giftId: number): Promise<any> => {
    const result = await API.put(
      `/package/receive-reservation-gift/${giftId}/`,
    );
    return result.data;
  },

  deleteReservationGiftById: async (reservId: number): Promise<AxiosResponse> => {
    const result = await API.delete(
      `/package/cancel-reservation-gift/${reservId}/`
    );
    return result;
  },
};

export const getUserOrdersByDate = async (date: string) => {
  return API.get(`/package/user-order-app/?filter_date=${date}`);
};

export const getUserOrdersByOrderId = async (orderId: string) => {
  return API.get(`/package/user-order-app/?order_id=${orderId}`);
}

export const getOrderListByPage = async (pageParam: Page) => {
  const response = await API.get(
    `/package/user-order-app/?page=${pageParam.page}${pageParam.queryString}`,
    {}
  );
  return response;
}

export const processOrderFreeBooking = async (orderId: string, amount: string) => {
  return API.post(`/package/order-free-booking/`, {
    orderId: orderId,
    amount: amount
  });
}