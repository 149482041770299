import React from "react";
import Navbar from "../../Components/Common/navbar";
import CardSection from "./CardSection";
import NavBottomBar from "../../Components/Common/NavBottomBar";
import MobileViewContainer from "../../Components/MobileViewContainer";

const Ticket = () => {
  return (
    <MobileViewContainer>
      <Navbar activeBar={2} />
      <CardSection />
      <NavBottomBar activeBar={2} />
    </MobileViewContainer>
  );
};

export default Ticket;
