import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from "reactstrap";
import { useAuthContext } from "../../Components/AuthContext";
import { LoginTitleImgModal } from "../../Components/Common/ManagerModal";

// kakao
import KAKAO_BTN from "../../assets/social/kakao_btn.png";

import { SITE_ID } from "../../assets/meta/meta";
import { authService } from "../../api/services/auth.service";
import { siteService } from "../../api/services/site.service";
import { KAKAO_AUTH_URL } from "../../api/auth";
import MobileViewContainer from "../../Components/MobileViewContainer";

// const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${CLIENT_ID}&state=${STATE_STRING}&redirect_uri=${CALLBACK_URL}`;

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserLogin, isLoading, error } = useAuthContext();

  /* 값을 index의 permission을 navbar를 통해 가지고 옴 */
  const [permission, setPermission] = React.useState(location.state ? location.state.citation : 0);
  const [loginTitleEditModal, setLoginTitleEditModal] = React.useState(false);

  const [siteName, setSiteName] = React.useState("");
  const [siteLogo, setSiteLogo] = React.useState("");
  const [siteSlogan, setSiteSlagon] = React.useState("");
  useEffect(() => {
    siteService.getSiteById(SITE_ID)
      .then((response) => {
        setSiteName(response.data.name);
        setSiteLogo(response.data.logo);
        setSiteSlagon(response.data.slogan);
        console.log("get site", response.data);
      })
      .catch((error) => console.log(error));
  }, []);


  const params = new URLSearchParams(location.search);
  const path = params.get("path");

  if (path) {
    window.localStorage.setItem("redirectTo", `${path}`);
    console.log("path", path);
  } else {
    if (window.localStorage.getItem("redirectTo")) {
      window.localStorage.removeItem("redirectTo");
    }
  }


  React.useEffect(() => {
    // 이미 로그인한 상태에서 잘못된 접근을 막는 코드
    if (isUserLogin && permission <= 1) {
      navigate(`/`);
    }
  }, [permission]);

  const handlingUnlinkKakao = async () => {
    try {
      await authService.unlinkKakaoAccount();
      // ...rest of unlink handling...
    } catch (error) {
      console.log(error);
    }
  }

  const handleKakaoLogin = async () => {
    try {
      window.location.replace(KAKAO_AUTH_URL);
    } catch (error) {
      console.error("Kakao login error:", error);
    }
  };

  return (
    <React.Fragment>
      <MobileViewContainer>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50 pt-4">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={siteLogo ? siteLogo : `/amazing-logo.png`} alt="" height="120" />
                    </Link>
                  </div>
                </div>
                {!!permission && permission >= 2 ? (
                  <Button color="primary" className="btn mb-3 border-0 position-absolute top-35 end-0" style={{ zIndex: "2" }} onClick={() => setLoginTitleEditModal(!loginTitleEditModal)}>
                    로그인 타이틀 이미지 수정
                  </Button>
                ) : null}
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card>
                  <CardBody className="p-4">
                    {error && <Alert color="danger">{error}</Alert>}
                    <div className="text-center mt-2">
                      <h5 className="text-primary fw-bold" style={{ fontFamily: "Gmarket-M" }}>
                        {siteName}
                      </h5>
                      <p className="text-muted">{siteSlogan}</p>
                    </div>

                    <div className="p-2 mt-4">
                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="fs-18 mb-4 title">카카오 1초 로그인 / 회원가입</h5>
                        </div>
                        <div>
                          <button
                            style={{ border: 0, backgroundColor: "#fee500", width: "100%", borderRadius: 12 }}
                            onClick={handleKakaoLogin}
                            disabled={isLoading}
                          >
                            <img className="img-fluid" src={KAKAO_BTN} alt="" />
                          </button>
                        </div>

                        <br />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              position: "absolute",
              bottom: "0",
            }}>
              <Col md={12} lg={12} xl={12}>
                <Card>
                  <CardBody className="px-4">
                    <div className="px-2">
                      <div className="text-center">
                        <Link to="/admin-login" className="text-muted">
                          <i className="mdi mdi-account-circle"></i>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <LoginTitleImgModal setVisible={setLoginTitleEditModal} visible={loginTitleEditModal} />
      </MobileViewContainer>
    </React.Fragment >
  );
};

export default Login;
