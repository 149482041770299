import React from "react";

import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Card, Nav, NavItem, NavLink, Row, Col, CardBody, Container } from "reactstrap";
import ReservationGift from "./ReservationGift";
import UserVoucherGift from "./VoucherGift";
import TicketGift from "./TicketGift";
import CouponGift from "./CouponGift";
import Navbar from "../../../Components/Common/navbar";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const GiftTabView = () => {
  const navigate = useNavigate();

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = React.useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <MobileViewContainer>
      <Navbar />
      <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
        <Row className="justify-content-center mt-4">
          <Col xxl={12} xl={12} md={12} sm={12}>
            <Card className="bg-white bg-opacity-50">
              <CardBody style={{ padding: 0 }}>
                <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      예약
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3");
                      }}
                    >
                      티켓
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      교환권
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "4",
                      })}
                      onClick={() => {
                        toggleCustom("4");
                      }}
                    >
                      쿠폰
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
              <div>
                {customActiveTab === "1" ? <ReservationGift navigate={navigate} /> : null}
                {customActiveTab === "2" ? <UserVoucherGift navigate={navigate} /> : null}
                {customActiveTab === "3" ? <TicketGift navigate={navigate} /> : null}
                {customActiveTab === "4" ? <CouponGift navigate={navigate} /> : null}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </MobileViewContainer>
  );
};
export default GiftTabView;
