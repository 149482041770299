import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./assets/scss/themes.scss";

import Index from "./pages/Landing/OnePage";
import Login from "./pages/Authentication/Login";
import KakaoPage from "./pages/Authentication/KakaoPage";
import ReservationPage from "./pages/Package";

import PackageItemPage from "./pages/Package/PackageItemPage";
import TicketItemPage from "./pages/Ticket/TicketItemPage";

import EventProductReception from "./pages/EventProduct/Reception/index";
import MyPage from "./pages/UserPage/MyPage";
import Purchases from "./pages/UserPage/Purchases";
import Notices from "./pages/Notice/NoticeList/index";
import NoticeDetail from "./pages/Notice/NoticeDetail";
import NoticeForm from "./pages/Notice/NoticeForm";
import CampMap from "./pages/CampMap/CampMap";
import OrderPage from "./pages/Order/OrderPackage";
import OrderDone from "./pages/Order/OrderPackage/OrderDone";
import Ticket from "./pages/Ticket/Ticket";
import SecretLogin from "./pages/Authentication/SecretLogin";
import AddOptionsDetail from "./pages/Detail/AddOptionsDetail";
import AddOptionsOrder from "./pages/Order/AddOptions";
import AddOptionsOrderDone from "./pages/Order/AddOptions/OrderDone";
import CancelOptionsDetail from "./pages/Detail/CancelOptionsDetail";
import InquiryList from "./pages/Inquiry/List";
import InquiryDetail from "./pages/Inquiry/Detail";
import InquiryForm from "./pages/Inquiry/Form";
import OrderTicketMultiPage from "./pages/Order/OrderTicketMultiPage";
import MultiOrderDone from "./pages/Order/OrderTicketMultiPage/MultiOrderDone";

import PhoneLogin from "./pages/Authentication/PhoneLogin";
import { useUserStore } from "./store/store";
import React, { useEffect, useState } from "react";
import AppDownload from "./pages/AppDownload";
import { SITE_ID, SUBDOMAIN } from "./assets/meta/meta";
import ShopList from "./pages/Shop/ShopList";
import OrderShopping from "./pages/Order/OrderShopping";
import ShoppingDetail from "./pages/Shop/ShoppingDetail";
import { QueryClient, QueryClientProvider } from "react-query";
import EventVoucherPage from "./pages/Events/Voucher";
import ShoppingPurchaseList from "./pages/UserPage/purchaseList/ShoppingPurchaseList";
import ReservationList from "./pages/UserPage/purchaseList/ReservationList";
import TicketPurchaseList from "./pages/UserPage/purchaseList/TicketPurchaseList";
import UserVoucher from "./pages/UserPage/purchaseList/UserVoucher";
import CouponBook from "./pages/UserPage/purchaseList/CouponBook";
import GiftSendTabView from "./pages/UserPage/purchaseList/GiftSendTabView";
import GiftTabView from "./pages/UserPage/purchaseList/GiftTabView";
import CancelList from "./pages/UserPage/purchaseList/CancelList";
import B2BTabView from "./pages/UserPage/B2BCoupon/B2BTabView";
import NotFoundPage from "./NotFoundPage";
import { KAKAO_JS_KEY, getAccessToken } from "./api/auth";
import AmazingPassPage from "./pages/Events/AmazingPass";
import AmazingPassPurchaseList from "./pages/UserPage/purchaseList/AmazingPassPurchaseList";
import GiftMain from "./pages/Gift";
import GiftReservationPage from "./pages/Gift/Reservation";
import GiftTicketPage from "./pages/Gift/Ticket";
import GiftVoucherPage from "./pages/Gift/Voucher";
import GiftCouponPage from "./pages/Gift/Coupon";
import PromotionB2BCouponPage from "./pages/Promotion/b2bCoupon";
import B2BInfo from "./pages/UserPage/B2BCoupon/B2BInfo";
import B2BPreCouponTabView from "./pages/UserPage/B2BPreCoupon/B2BPreCouponTabView";
import B2BPreCouponInfo from "./pages/UserPage/B2BPreCoupon/B2BPreCouponInfo";
import B2BVoucherTabView from "./pages/UserPage/B2BVoucher/B2BVoucherTabView";
import B2BVoucherInfo from "./pages/UserPage/B2BVoucher/B2BVoucherInfo";
import PromotionB2BPrecouponPage from "./pages/Promotion/B2BPrecoupon";
import PromotionB2BVoucherPage from "./pages/Promotion/B2BVoucher";
import MembershipDetailScreen from "./pages/UserPage/Membership";
import { logEvent } from "firebase/analytics";
import { analytics } from "./store/firebase";
const { Kakao } = window;

import { HECTO_API_URL } from "./common/payments/hecto/api";
import AmazingPassScreen from "./pages/UserPage/AmazingPass";
import AmazingpassOrderScreen from "./pages/UserPage/AmazingPass/amazingpassOrder";
import { Container } from "reactstrap";
import { Oval } from "react-loader-spinner";
import LodgingPackagePage from "./pages/LodgingPackage";
import { LodgingPackageDetailPage } from "./pages/LodgingPackage/packageDetail";
import OrderFail from "./pages/Order/OrderPackage/OrderFail";
import { siteService } from "./api/services/site.service";
import { AuthProvider, useAuthContext } from "./Components/AuthContext";
import MobileViewContainer from "./Components/MobileViewContainer";

const queryClient = new QueryClient();

// Create a protected route wrapper component
const ProtectedRoute = ({ children }) => {
  const { accessToken } = useAuthContext();
  if (!accessToken) {
    return <Navigate to="/login" replace={true} />;
  }
  return children;
};

function AppContent() {
  const [isWebPurchase, setIsWebPurchase, isWebPurchaseLodge, setIsWebPurchaseLodge] = useUserStore((state) => [state.isWebPurchase, state.setIsWebPurchase, state.isWebPurchaseLodge, state.setIsWebPurchaseLodge]);
  const [isManaged, setIsManaged] = useUserStore((state) => [state.isManaged, state.setIsManaged]);
  const [isWebPurchaseTicket, setIsWebPurchaseTicket, setIsWebShopping] = useUserStore((state) => [state.isWebPurchaseTicket, state.setIsWebPurchaseTicket, state.setIsWebShopping]);
  const [site, setSite] = useUserStore((state) => [state.site, state.setSite]);
  const [availableSites, setAvailableSites] = useUserStore((state) => [state.availableSites, state.setAvailableSites]);
  const [sitePaymentKeys, setSitePaymentKeys] = useUserStore((state) => [state.sitePaymentKeys, state.setSitePaymentKeys]);

  const [siteDetail, setSiteDetail] = useState(null);

  const { userInfo, isLoading } = useAuthContext();

  useEffect(() => {
    siteService.getSiteById(SITE_ID)
      .then((response) => {
        const data = response.data;
        setSite(data);
        setIsWebPurchase(data.is_web_purchase);
        setIsWebPurchaseLodge(data.is_web_purchase_lodge);
        setIsWebPurchaseTicket(data.is_web_purchase_ticket);
        setIsWebShopping(data.is_web_shopping);
        setIsManaged(data.is_managed);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await siteService.getSitesApp();
        const data = res.data;
        console.log("site data", data);
        console.log("site SITE_ID", SITE_ID);
        const filterSites = data.result.sites.filter((site) => site.open_state === "confirm" || site.open_state === "open");
        setAvailableSites(filterSites);
        setSitePaymentKeys(data.result.client_keys);

        const siteDetail = data.result.sites.find((site) => site.id === SITE_ID);
        setSiteDetail(siteDetail);

        console.log("siteDetail", siteDetail);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Kakao.cleanup();
    Kakao.init(KAKAO_JS_KEY);
    console.log(Kakao.isInitialized());
  }, []);

  useEffect(() => {
    try {
      logEvent(analytics, `amazingticket_web_${SUBDOMAIN}`, { subdomain: SUBDOMAIN, user_id: userInfo ? userInfo.id : "" });
    } catch (error) {
      console.log(error);
    }
  }, [userInfo]);

  if (!siteDetail || isLoading) {
    return (
      <MobileViewContainer>
        <Container fluid style={{
          justifyContent: "center", alignItems: "center",
          minHeight: "100vh",
          display: "flex", paddingBottom: "100px"
        }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Oval visible={true} height="50" width="50" color="#888" ariaLabel="oval-loading" secondaryColor="#fff" />
            <div style={{ marginTop: "10px", color: "#888" }}>사이트 정보를 로딩중입니다...</div>
            <div style={{ marginTop: "10px", color: "#888" }}>화면이 계속해서 로딩되지 않을 경우 새로고침을 해보세요.</div>
          </div>
        </Container>
      </MobileViewContainer>
    );
  }

  if (siteDetail && siteDetail.open_state === "close") {
    return (
      <MobileViewContainer>
        <Container fluid style={{
          justifyContent: "center", alignItems: "center",
          minHeight: "100vh",
          display: "flex", paddingBottom: "100px"
        }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>서비스가 종료되었습니다.</div>
          </div>
        </Container>
      </MobileViewContainer>
    )
  }

  return (
    <>
      <Helmet>
        <script src="https://pay.nicepay.co.kr/v1/js/" type="text/javascript" />
        <script src={`${HECTO_API_URL}/resources/js/v1/SettlePG_v1.2.js`} type="text/javascript" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Index isWebPurchase={isWebPurchase} isWebPurchaseTicket={isWebPurchaseTicket} isManaged={isManaged} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/phone-login" element={<PhoneLogin />} />
          <Route path="/admin-login" element={<SecretLogin />} />
          <Route path="/kakao" element={<KakaoPage />} />
          <Route path="/reception/:type" element={<EventProductReception />} />

          {/* Protected Routes */}
          <Route path="/my-page" element={<ProtectedRoute><MyPage /></ProtectedRoute>} />
          <Route path="/my-page/shopping-purchase" element={<ProtectedRoute><ShoppingPurchaseList /></ProtectedRoute>} />
          <Route path="/my-page/reservation" element={<ProtectedRoute><ReservationList /></ProtectedRoute>} />
          <Route path="/my-page/ticket" element={<ProtectedRoute><TicketPurchaseList /></ProtectedRoute>} />
          <Route path="/my-page/voucher" element={<ProtectedRoute><UserVoucher /></ProtectedRoute>} />
          <Route path="/my-page/amazingpass" element={<ProtectedRoute><AmazingPassPurchaseList /></ProtectedRoute>} />
          <Route path="/my-page/coupon" element={<ProtectedRoute><CouponBook /></ProtectedRoute>} />
          <Route path="/my-page/gift-send" element={<ProtectedRoute><GiftSendTabView /></ProtectedRoute>} />
          <Route path="/my-page/gift-box" element={<ProtectedRoute><GiftTabView /></ProtectedRoute>} />
          <Route path="/my-page/cancel" element={<ProtectedRoute><CancelList /></ProtectedRoute>} />
          <Route path="/my-page/b2b/coupon" element={<ProtectedRoute><B2BTabView /></ProtectedRoute>} />
          <Route path="/my-page/b2b-info/coupon" element={<ProtectedRoute><B2BInfo /></ProtectedRoute>} />
          <Route path="/my-page/b2b/precoupon" element={<ProtectedRoute><B2BPreCouponTabView /></ProtectedRoute>} />
          <Route path="/my-page/b2b-info/precoupon" element={<ProtectedRoute><B2BPreCouponInfo /></ProtectedRoute>} />
          <Route path="/my-page/b2b/voucher" element={<ProtectedRoute><B2BVoucherTabView /></ProtectedRoute>} />
          <Route path="/my-page/b2b-info/voucher" element={<ProtectedRoute><B2BVoucherInfo /></ProtectedRoute>} />
          <Route path="/my-page/membership" element={<ProtectedRoute><MembershipDetailScreen /></ProtectedRoute>} />

          {/* Public Routes */}
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/notice" element={<Notices />} />
          <Route path="/notice/detail/:id" element={<NoticeDetail />} />
          <Route path="/notice/form/:id" element={<NoticeForm />} />
          <Route path="/inquiry" element={<InquiryList />} />
          <Route path="/inquiry/detail/:id" element={<InquiryDetail />} />
          <Route path="/inquiry/form/:id" element={<InquiryForm />} />
          <Route path="/camp-map" element={<CampMap />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/order-ticket-multi" element={<OrderTicketMultiPage />} />
          <Route path="/order-done-multi/:orderId" element={<MultiOrderDone />} />
          <Route path="/order-done/:orderId" element={<OrderDone />} />
          <Route path="/add-options-detail/:id" element={<AddOptionsDetail />} />
          <Route path="/add-options-order" element={<AddOptionsOrder />} />
          <Route path="/add-options-order-done/:orderId" element={<AddOptionsOrderDone />} />
          <Route path="/cancel-options-detail/:id" element={<CancelOptionsDetail />} />
          <Route path="/shop" element={<ShopList />} />
          <Route path="/shop/:id" element={<ShoppingDetail />} />
          <Route path="/order-shop" element={<OrderShopping />} />
          {/* <Route path="/apply/camp-ulsan-free-voucher-2023-12" element={<ApplyCampUlsan />} /> */}
          <Route path="/reservation" element={<ReservationPage />} />
          <Route path="/package" element={<ReservationPage />} />
          <Route path="/package/:id" element={<PackageItemPage />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/ticket/:id" element={<TicketItemPage />} />
          <Route path="/app-download" element={<AppDownload path="ticket" />} />
          <Route path="/event-voucher/:id" element={<EventVoucherPage />} />
          <Route path="/event-amazingpass" element={<AmazingPassPage />} />
          <Route path="/gift" element={<GiftMain />} />
          <Route path="/gift/reservation/:id" element={<GiftReservationPage />} />
          <Route path="/gift/ticket/:id" element={<GiftTicketPage />} />
          <Route path="/gift/voucher/:id" element={<GiftVoucherPage />} />
          <Route path="/gift/coupon/:id" element={<GiftCouponPage />} />
          <Route path="/promotion/coupon/:share_pin_code" element={<PromotionB2BCouponPage />} />
          <Route path="/promotion/voucher/:share_pin_code" element={<PromotionB2BVoucherPage />} />
          <Route path="/promotion/precoupon/:share_pin_code" element={<PromotionB2BPrecouponPage />} />
          <Route path="/amazingpass" element={<AmazingPassScreen />} />
          <Route path="/amazingpass/order" element={<AmazingpassOrderScreen />} />
          <Route path="/lodging-package" element={<LodgingPackagePage />} />
          <Route path="/lodging-package/:id" element={<LodgingPackageDetailPage />} />
          <Route path="/order-fail" element={<OrderFail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
