
export default function MobileViewContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="layout-wrapper landing" style={{
            backgroundColor: "#fff9fa",
        }}>
            <div style={{
                maxWidth: "640px",
                margin: "0 auto",
                backgroundColor: "#fff",
                minHeight: "100vh",
                // overflow: "auto",
            }}>
                {children}
            </div>
        </div>
    )
}
