import { AxiosResponse } from "axios";
import { API } from "../index";

export const fileService = {
  uploadFile: async ({url, file, groupKey, userId, name, onUploadProgress}: {
    url: string,
    file: File,
    groupKey?: string,
    userId?: number,
    name?: string,
    onUploadProgress?: (progress: number) => void
  }): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("image", file, file.name);
    if (groupKey) formData.append("group_key", groupKey);
    if (userId) formData.append("user_id", userId.toString());
    if (name) formData.append("name", name);

    return await API.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: onUploadProgress ? (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onUploadProgress(percentCompleted);
      } : undefined
    });
  }
};