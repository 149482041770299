import axios, { AxiosError } from "axios";
import { getAccessToken, removeAccessToken, setAccessToken } from "./auth";
import { SITE_ID, SUBDOMAIN } from "../assets/meta/meta";
import { read_django_error } from "../common/utils/format_util";
import { hectoClientKey } from "../common/payments/hecto/keys";
import { nicepayClientId } from "../common/payments/nicepay/keys";
import { variantKey } from "../common/payments/toss/api";

export const NODE_ENV = process.env.NODE_ENV;
const isTest = NODE_ENV === "development" || SUBDOMAIN === "test";
export const BACKEND_HOST =
  isTest
    ? "https://devtotal.amazingticket.site"
    : "https://total.amazingticket.site";

export const SITE_URL = `https://${SUBDOMAIN}.amazingticket.site`;

const verbose = NODE_ENV === "development";

if (verbose) {
  console.log("NODE_ENV:", NODE_ENV);
  console.log("BACKEND_HOST:", BACKEND_HOST);
  console.log("SITE_URL:", SITE_URL);
  console.log("SITE_ID:", SITE_ID);

  console.log("PG_TOSS_CLIENT_KEY:", variantKey);
  console.log("PG_NICEPAY_CLIENT_ID:", nicepayClientId);
  console.log("PG_HECTO_CLIENT_KEY:", hectoClientKey);
}

export const API = axios.create({
  baseURL: BACKEND_HOST,
  timeout: 1000 * 20,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    if (verbose)
      console.log(
        `api:req:[`,
        config.method,
        "]",
        config.headers?.authorization ? config.headers.authorization.toString().slice(0, 20) + "..." : "",
        config.baseURL,
        config.url,
        config.params ? config.params : "",
        config.data ? config.data : ""
      );

    if (!config.headers?.Authorization) {
      const accessToken = getAccessToken();
      if (accessToken) {
        if (!config.headers) config.headers = {};
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (verbose) console.log(`api:res:[`, response.config.method, "]", response.config.baseURL, response.config.url, response.status);
    return response;
  },
  (error: AxiosError) => {
    console.log("api:error:", error.cause, error.message);
    if (error.response) {
      // lets standardize the error message
      const _data: ErrorData | string | undefined = error.response?.data as any;
      let message = "";

      if (_data) {
        if (typeof _data === "string") {
          const django_error = read_django_error(_data);
          message = `${django_error.code}: ${django_error.message}`;
        } else {
          if (_data.detail) {
            message = _data.detail;
          } else if (_data.message) {
            message = _data.message;
          } else if (_data.error) {
            message = _data.error;
          } else if (_data.non_field_errors) {
            message = _data.non_field_errors.join(", ");
          } else {
            for (const key in _data) {
              if (Array.isArray(_data[key])) {
                message = `${key}: ${_data[key].join(", ")}`;
                break;
              } else {
                message = `${key}: ${_data[key]}`;
                break;
              }
            }
          }
        }
      }
      console.log("api:error:message:", message);
      // overwrite the error message
      if (message) error.message = message;

      const { status } = error.response;
      if (status === 502 || status === 503 || (status >= 500 && status < 600)) {
        alert("서버가 불안정합니다. 잠시 후 다시 시도해주세요.");
      } else if (status === 401) {
        // alert("다시 로그인해주세요.");
        removeAccessToken();
      } else if (status === 403) {
        alert("접근 권한이 없습니다.");
      } else if (status === 400 && message === "EXPIRED TOKEN") {
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        removeAccessToken();
      }
    } else if (error.request) {
      // alert("서버 응답이 없습니다. 잠시 후 다시 시도해주세요.");
    } else {
      // alert("요청을 설정하는 중에 오류가 발생했습니다.");
    }
    return Promise.reject(error);
  }
);

export const updateAccessToken = (token: string) => {
  setAccessToken(token);
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

type ErrorData = {
  detail?: string;
  message?: string;
  error?: string;
  non_field_errors?: string[];
  [key: string]: any;
};