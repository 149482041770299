import { AxiosResponse } from "axios";
import { API } from "../index";

export const giftService = {
  // Coupon gifts
  getCouponShareGift: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/coupon/share-gift/${id}/`);
  },

  // Reservation gifts
  getReservationShareGift: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/package/share-gift/${id}/`);
  },
  
  putReceiveReservationGift: async (id: string): Promise<AxiosResponse> => {
    return await API.put(`/package/receive-reservation-gift/${id}/`, {});
  },

  // Voucher gifts
  getVoucherShareGift: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/voucher/share-gift/${id}/`);
  },

  putReceiveVoucherGift: async (id: string): Promise<AxiosResponse> => {
    return await API.put(`/voucher/receive-user-voucher-gift/${id}/`, {});
  },

  // Event product details
  getSingleEventProduct: async (type: "coupon" | "voucher", uid: string): Promise<AxiosResponse> => {
    return await API.get(`/${type}/single/${uid}`);
  },

  processEventProduct: async (type: "coupon" | "voucher", uid: string): Promise<AxiosResponse> => {
    return await API.post(`/${type}/process/${uid}/`, null);
  }
};