
export const numberWithCommas = (x) => {
    if (!x) {
        return 0;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function read_django_error(htmlText) {
    // Regular expression to find the error message in <h1> tags
    const errorMessageRegex = /<h1>([^<]*)<\/h1>/s;

    // Regular expression to find the exception value in <pre class="exception_value">
    const exceptionValueRegex = /<pre class="exception_value">([^<]*)<\/pre>/s;

    // Using match() method to find the error message
    const errorMessageMatch = htmlText.match(errorMessageRegex);
    const errorMessage = errorMessageMatch ? errorMessageMatch[1].trim() : null;

    // Using match() method to find the exception value
    const exceptionValueMatch = htmlText.match(exceptionValueRegex);
    const exceptionValue = exceptionValueMatch ? exceptionValueMatch[1].trim() : null;

    // console.log('Error Message:', errorMessage);
    // console.log('Exception Value:', exceptionValue);

    return {
        code: errorMessage,
        message: exceptionValue,
    };
}