import { AxiosResponse } from "axios";
import { API } from "../index";

export const inquiryService = {
  getComments: async (articleId: string): Promise<AxiosResponse> => {
    return await API.get(`/inquiry/comments/?article_id=${articleId}`);
  },

  getArticleList: async (subdomain: string, modes: string): Promise<AxiosResponse> => {
    return await API.get(`/inquiry/articles/?subdomain=${subdomain}&modes=${modes}`);
  },

  getInquiryArticleList: async (query: string): Promise<AxiosResponse> => {
    return await API.get(`/inquiry/article-list/${query}`);
  },

  getInquiryHeadBanner: async (): Promise<AxiosResponse> => {
    return await API.get(`/inquiry/head-banner/`);
  },

  postInquiryExtraInfo: async (articleId: number, need: boolean, extraInfo: string): Promise<AxiosResponse> => {
    return await API.post(`/inquiry/extra-info/`, {
      article_id: articleId,
      need_extra_info: need,
      extra_info: extraInfo,
    });
  },

  getArticleById: async (id: string): Promise<AxiosResponse> => {
    return await API.get(`/inquiry/articles/${id}/`);
  },

  deleteArticleById: async (id: string): Promise<AxiosResponse> => {
    return await API.delete(`/inquiry/articles/${id}/`);
  }
};