import { AxiosResponse } from "axios";
import { API } from "../index";

export const voucherService = {
  getVoucherSendData: async (): Promise<AxiosResponse> => {
    return await API.get(`/voucher/user/`);
  },

  postValidateVoucher: async (): Promise<AxiosResponse> => {
    return await API.post(`/voucher/check-user-vouchers/`);
  },

  postVoucherStateById: async (itemId: number): Promise<AxiosResponse> => {
    return await API.post(`voucher/process/${itemId}/`);
  },

  deleteCancelGiftById: async (itemId: number): Promise<AxiosResponse> => {
    return await API.delete(`/voucher/cancel-user-voucher-gift/${itemId}/`);
  },

  getVoucherResponseByNum: async (phoneNum: string): Promise<AxiosResponse> => {
    return await API.get(`/voucher/user-voucher-gift/?recipient=${phoneNum}`);
  },

  patchReceiveVoucherGiftById: async (giftId: number): Promise<AxiosResponse> => {
    return await API.put(`/voucher/receive-user-voucher-gift/${giftId}/`);
  }
};

export const getUserVoucherCount = async () => {
  return API.get('/voucher/unchecked-count/');
};
