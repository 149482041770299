import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Common/navbar";
import styled from "styled-components";
import { API, BACKEND_HOST } from "../../../api";
import { Button } from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { KAKAO_JS_KEY, getAccessToken } from "../../../api/auth";
import { SITE_ID } from "../../../assets/meta/meta";
import { eventService } from '../../../api/services/event.service';
import { siteService } from '../../../api/services/site.service';
import MobileViewContainer from "../../../Components/MobileViewContainer";

const { Kakao } = window;

const TicketWrapper = styled.div`
  width: 90%;
  min-height: 176px;
  position: relative;
  background-color: #fff;
  border: 1px solid #f6475f;
  padding: 16px 16px 20px 20px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #f6475f;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #fff0f2;
  }
`;

const TicketContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const EventVoucherPage = () => {
  const location = useLocation();

  const accessToken = getAccessToken();
  const [voucherItem, setVoucherItem] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [download, setDownload] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [siteName, setSiteName] = React.useState("");
  const [siteLogo, setSiteLogo] = React.useState("");
  const [siteSlogan, setSiteSlagon] = React.useState("");
  useEffect(() => {
    siteService.getSiteById(SITE_ID)
      .then((response) => {
        setSiteName(response.data.name);
        setSiteLogo(response.data.logo);
        setSiteSlagon(response.data.slogan);
        console.log("get site", response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    eventService.getBookRule(id)
      .then((response) => {
        console.log("get tickets", response.data);
        if (response.data.voucher_product) {
          setVoucherItem(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (accessToken) {
      eventService.checkBook(id)
        .then((response) => {
          console.log("get2 tickets", response.data);
          setDownload(response.data.downloaded);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken, id]);

  const shareKakao = () => {
    if (!voucherItem) return;

    Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: siteName,
        description: voucherItem.voucher_product.name,
        imageUrl: siteLogo ? siteLogo : `https://${window.location.hostname}/amazing-logo.png`,
        link: {
          webUrl: window.location.href,
          mobileWebUrl: window.location.href,
          // androidExecutionParams: "amazingcamp://siteinfo/40",
          // iosExecutionParams: "amazingcamp://siteinfo/40",
        },
      },
      buttons: [
        {
          title: "바로가기",
          link: {
            webUrl: window.location.href,
            mobileWebUrl: window.location.href,
            // androidExecutionParams: "amazingcamp://siteinfo/40",
            // iosExecutionParams: "amazingcamp://siteinfo/40",
          },
        },
      ],
    });
  };

  return (
    <MobileViewContainer>
      <Navbar />
      <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
        {!isLoading && voucherItem && voucherItem !== null ? (
          <>
            <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
              <span style={{ color: "#ff5a5f" }}>이벤트</span>
              <br />
              무료 교환권
            </h2>
            <TicketWrapper>
              <TicketContent>
                {/* {voucherItem?.voucher_product.image ? <img src={voucherItem.voucher_product.image} alt="Voucher" style={{ width: "100%", marginBottom: "10px", maxWidth: "280px" }} /> : null} */}

                <p style={{ fontWeight: "600", fontSize: "20px" }}>{voucherItem?.voucher_product.name}</p>

                <p style={{ marginTop: "10px", color: "#999" }}>만료일: {
                  voucherItem?.voucher_product?.expire_date ?
                    voucherItem?.voucher_product?.expire_date :
                    voucherItem?.voucher_product?.expire_days ?
                      `발급일로부터 ${voucherItem?.voucher_product?.expire_days}일` :
                      "없음"
                }</p>

                {voucherItem.state === "노출중" ? (
                  <Button
                    style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", opacity: download ? 0.5 : 1, float: "right" }}
                    onClick={() => {
                      if (!accessToken) {
                        alert("로그인이 필요합니다.");
                        navigate(`/login?path=${location.pathname}`);
                        return;
                      }
                      if (download) {
                        alert("이미 교환권을 받으셨습니다.");
                        return;
                      } else {
                        fetch(`${BACKEND_HOST}/voucher/book/download/${id}/`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                          },
                        })
                          .then((response) => response.json())
                          .then((data) => {
                            console.log("get2 tickets", data);
                            setDownload(true);
                            alert("교환권을 받았습니다.");
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    }}
                  >
                    교환권 받기
                  </Button>
                ) : (
                  <Button style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", float: "right", opacity: 0.5 }} disabled>
                    이벤트가 종료되었습니다.
                  </Button>
                )}
              </TicketContent>
            </TicketWrapper>

            <div style={{ textAlign: "start", width: "90%", marginTop: "12px", lineHeight: "28px", color: "#000" }}>

              <p style={{ marginTop: "10px" }}>
                다운로드 가능기간: {voucherItem?.start_date} ~ {voucherItem?.end_date}
              </p>
            </div>

            <div style={{ textAlign: "start", width: "90%", margin: "20px auto", lineHeight: "28px", color: "#000" }}>
              <p style={{ fontWeight: "600" }}>유의사항</p>
              <div dangerouslySetInnerHTML={{ __html: voucherItem?.voucher_product.precautions }}></div>
            </div>

            {/* 카카오톡 공유하기 버튼 */}
            <div style={{ width: "90%", margin: "30px auto", display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  fontFamily: "Gmarket-M",
                  backgroundColor: "#ff5a5f",
                  borderWidth: 0,
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 20px",
                  gap: "10px",
                }}
                onClick={() => {
                  shareKakao();
                }}
              >
                <i className="ri-share-fill"></i>
                카카오톡 공유하기
              </Button>
            </div>
          </>
        ) : isLoading ? (
          <div>로딩중...</div>
        ) : (
          <div>정보를 찾을 수 없습니다.</div>
        )}
      </div>
    </MobileViewContainer>
  );
};

export default EventVoucherPage;
