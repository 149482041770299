import React from "react";
import { Button, Modal } from 'reactstrap';
import jwtDecode from "jwt-decode";
import { getAccessToken } from "../../api/auth";
import { noticeService } from "../../api/services/notice.service";


const AnnounceModal = (props)=>{
    
    const togModal = ()=>{
        props.setVisible(!props.visible);
    }

    function readAnnounce(){
        // 공지 읽음 처리
        const token = getAccessToken();
        const decoded = jwtDecode(token);
        noticeService.postAnnounceRead(decoded.user_id, props.id)
            .catch((err)=>{
                console.log(err);
            });       
    }

    return(
        <Modal isOpen={ !!props.visible } toggle={togModal} centered>
            <div className="modal-content p-2">
            <a href={props.link} target="_blank" rel="noreferrer">
                <img className="img-fluid" style={{resize:"horizontal"}} src={props.img} alt="공지팝업 이미지" />
            </a>

                <div className="d-flex justify-content-between mt-3">
                    { !getAccessToken() ? <div></div> :<Button color="greenlight" className="ms-2 btn" onClick={()=>{
                        readAnnounce();
                        togModal();
                    }}>
                        읽음 확인
                    </Button>}
                    
                    <Button color="light" className="ms-2 btn" onClick={togModal}>
                        닫기
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AnnounceModal;