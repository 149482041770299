import React from "react";
import { useParams } from "react-router-dom";

import Navbar from "../../../Components/Common/navbar";
import UnreceivedList from "./UnreceivedList";
import MobileViewContainer from "../../../Components/MobileViewContainer";

// coupon, voucher 수신하는 페이지
const EventProductReception = () => {
    const { type } = useParams();

    return (
        <MobileViewContainer>
            <Navbar />
            <UnreceivedList type={type} />
        </MobileViewContainer>
    );
}

export default EventProductReception;