import Navbar from "../../../Components/Common/navbar";
import InquiryListSection from "./InquiryListSection";
import NavBottomBar from "../../../Components/Common/NavBottomBar";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const InquiryList = () => {
  return (
    <MobileViewContainer>
      <Navbar activeBar={5} />
      <InquiryListSection />
      <NavBottomBar activeBar={5} />
    </MobileViewContainer>
  );
};

export default InquiryList;
