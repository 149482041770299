import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap";
import Navbar from "../../../Components/Common/navbar";
import { getUserOrdersByOrderId } from "../../../api/services/package.service";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const OrderDone = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    const fetchUserOrder = async () => {
      try {
        const response = await getUserOrdersByOrderId(orderId);
        setOrders(response.data.orders);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserOrder();
  }, [orderId, navigate]);

  return (
    <MobileViewContainer>
      <Navbar />
      <h3 className="border-success text-center my-3 fw-bold mt-4" style={{ fontFamily: "Gmarket-M" }}>
        주문완료
      </h3>

      <Card>
        <CardBody>
          <Row>
            <Col lg={12}>
              <div className="table-responsive">
                <Table className="table-borderless text-center mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">상품명</th>
                      <th scope="col">자리</th>
                      <th scope="col">예약날짜</th>
                      <th scope="col">결제일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(orders || []).map((order, key) => (
                      <tr key={key}>
                        <td>{order.name}</td>
                        <td className="d-flex justify-content-center">
                          <div>{order.spot} &nbsp;</div>
                        </td>
                        <td>{order.reservationDate}</td>
                        <td>{order.orderDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="d-flex justify-content-center">
        <Button onClick={() => navigate("/my-page")} color="success" style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", boxShadow: "none", border: "none" }}>
          주문확인하러 가기
        </Button>
      </div>
    </MobileViewContainer>
  );
};
export default OrderDone;
