import Navbar from "../../../Components/Common/navbar";
import NoticeDetailSection from "./NoticeDetailSection";
import MobileViewContainer from "../../../Components/MobileViewContainer";

// coupon, voucher 수신하는 페이지
const NoticeDetail = () => {

    return (
        <MobileViewContainer>
            <Navbar />
            <NoticeDetailSection />
        </MobileViewContainer>
    );
}

export default NoticeDetail;