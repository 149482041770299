import axios, { AxiosResponse } from "axios";
import { API } from "../index";

export const authService = {
  postPhoneAuth: async (phoneNum: string): Promise<AxiosResponse> => {
    return await API.post(`/auth/`, { phone_num: phoneNum });
  },

  postPhoneLogin: async (phoneNum: string): Promise<AxiosResponse> => {
    return await API.post(`/api/token/`, { phone_num: phoneNum });
  },

  postKakaoToken: async (kakaoToken: string): Promise<AxiosResponse> => {
    return await API.post(`/api/token/`, { kakao_token: kakaoToken });
  },

  exchangeKakaoCode: async (code: string, restApiKey: string): Promise<AxiosResponse> => {
    return axios.post(`https://kauth.kakao.com/oauth/token`,
      `grant_type=authorization_code&client_id=${restApiKey}&code=${code}`,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
  },

  unlinkKakaoAccount: async (): Promise<AxiosResponse> => {
    return await axios.post(`https://kapi.kakao.com/v1/user/unlink`, {}, {
      headers: {
        baseURL: ''
      }
    });
  },

  adminLogin: async (phone_num: string, password: string): Promise<AxiosResponse> => {
    return await API.post("/api/token/admin/", { phone_num, password });
  },

  refreshToken: () => {
    return API.post('/api/token/refresh/', {
      refresh_token: localStorage.getItem('refresh_token'),
    });
  }
};

export const changeCancelableUserConfirm = async (isConfirm: boolean) => {
  return API.put('/sites/cancelable-user-confirm/', {
    confirm: isConfirm,
    read: true
  });
};

export const changeCancelableUserRead = async () => {
  return API.put('/sites/cancelable-user-confirm/', {
    confirm: false,
    read: true
  });
};