import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import { API, updateAccessToken } from "../api";
import { authService } from "../api/services/auth.service";
import { userService } from "../api/services/user.service";
import {
  getAccessToken, setAccessToken, removeAccessToken,
  getRefreshToken, setRefreshToken, removeRefreshToken
} from "../api/auth";
import { UserInfo } from "@/api/type";

// Create a context
const AuthContext = createContext<{
  accessToken: string;
  userInfo: UserInfo | null;
  isUserLogin: boolean;
  login: ({ username, password }: { username: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  refreshUserInfo: () => Promise<void>;
  loginSocial: (provider: "kakao", token: string) => Promise<void>;
  isLoading: boolean;
  error: string;
}>({
  accessToken: "",
  userInfo: null,
  isUserLogin: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refreshUserInfo: () => Promise.resolve(),
  loginSocial: () => Promise.resolve(),
  isLoading: false,
  error: "",
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userInfo, setUserInfo] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const abortControllerRef = useRef<AbortController | null>(null);
  const refreshIntervalRef = useRef<any>(null);

  const handleError = useCallback((error: unknown) => {
    let message = "";
    const axiosError = error as AxiosError;
    if (axiosError.isAxiosError) {
      message = axiosError.response?.data?.detail || axiosError.response?.data?.message;
      if (!message) {
        if (typeof axiosError.response?.data === "string") message = axiosError.response?.data;
        else if (typeof axiosError.response?.data === "object") message = JSON.stringify(axiosError.response?.data);
      }
    } else if (error instanceof Error) {
      message = error.message;
    } else if (typeof error === "string") {
      message = error;
    }
    setError(message || "오류가 발생했습니다.");

    throw new Error(message);
  }, []);

  const refreshUserInfo = useCallback(async () => {
    try {
      const response = await userService.getUserInfo();
      setUserInfo({
        ...response.data,
        phone_num: response.data.phone,
      });
    } catch (err) {
      console.log("AuthProvider: Error refreshing user info", err);
      handleError(err);
    }
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const result = await authService.refreshToken();
      const newAccessToken = result.data.access_token;
      const newRefreshToken = result.data.refresh_token;
      updateAccessToken(newAccessToken);
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      return true;
    } catch (err) {
      console.log("AuthProvider: Error refreshing token", err);
      await logout();
      return false;
    }
  }, []);

  const startRefreshTokenInterval = useCallback(() => {
    if (refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current);
    }

    // Check every 10 minutes
    refreshIntervalRef.current = setInterval(async () => {
      await refreshToken();
    }, 10 * 60 * 1000);
  }, [refreshToken]);

  const login = useCallback(async ({ username, password }: { username: string; password: string }) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    setIsLoading(true);
    try {
      const result = await authService.adminLogin(username, password);
      const accessToken = result.data.access_token;
      const refreshToken = result.data.refresh_token;
      updateAccessToken(accessToken);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      await refreshUserInfo();
      startRefreshTokenInterval();
    } catch (err) {
      console.log("AuthProvider: Error signing in", err);
      const axiosError = err as AxiosError;
      if (axiosError.isAxiosError) {
        if (axiosError.response?.status === 400 || axiosError.response?.status === 401) {
          handleError(new Error("아이디 또는 비밀번호가 일치하지 않습니다."));
          return;
        }
      }
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [refreshUserInfo, startRefreshTokenInterval]);

  const loginSocial = useCallback(async (provider: "kakao", token: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    setIsLoading(true);
    try {
      const result = await authService.postKakaoToken(token);
      if (result.data.has_user) {
        const accessToken = result.data.token.access_token;
        const refreshToken = result.data.token.refresh_token;
        updateAccessToken(accessToken);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        console.log("AuthProvider: Kakao login success");
        await refreshUserInfo();
      } else {
        throw new Error("사용자 정보를 찾을 수 없습니다.");
      }
    } catch (err) {
      console.log("AuthProvider: Error with social login", provider, err);
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [refreshUserInfo]);

  const logout = useCallback(async () => {
    setIsLoading(true);
    try {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
        refreshIntervalRef.current = null;
      }
      removeAccessToken();
      setUserInfo(null);
      setError("");

      // Clear local storage
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("kakao_access_token");
    } catch (err) {
      console.log("AuthProvider: Error logging out", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Check auth state on mount
  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      refreshUserInfo();
      startRefreshTokenInterval();
    }

    // Cleanup on unmount
    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    };
  }, [refreshUserInfo, startRefreshTokenInterval]);

  return (
    <AuthContext.Provider
      value={{
        accessToken: getAccessToken() || "",
        userInfo,
        isUserLogin: !!userInfo,
        login,
        logout,
        refreshUserInfo,
        loginSocial,
        isLoading,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuthContext };
