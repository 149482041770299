import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { useAuthContext } from "../../Components/AuthContext";
import MobileViewContainer from "../../Components/MobileViewContainer";

const SecretLogin = () => {
  const navigate = useNavigate();
  const { login, isLoading, error } = useAuthContext();

  const [phonenum, setPhonnum] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      await login({ username: phonenum, password });
      const redirectTo = window.localStorage.getItem("redirectTo");
      if (redirectTo) {
        window.localStorage.removeItem("redirectTo");
        navigate(redirectTo);
      } else {
        navigate("/my-page");
      }
    } catch (error) {
      // Error handling is now managed by AuthContext
      console.warn(error);
      alert("로그인에 실패했습니다." + error.message);
    }
  };

  return (
    <MobileViewContainer>
      <div className="auth-page-content">
        <Container>
          <Row>
            <div style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "20px",

            }}>
              <h1>로그인</h1>
            </div>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {error && <Alert color="danger">{error}</Alert>}
                  <Form onSubmit={(ev) => { ev.preventDefault(); handleLogin(); }}>
                    <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                      <Label for="formPhonenum" className="mr-sm-2">
                        전화번호
                      </Label>
                      <Input
                        type="tel"
                        name="phonenum"
                        id="formPhonenum"
                        placeholder="phone number"
                        onChange={(ev) => setPhonnum(ev.currentTarget.value)}
                      />
                    </FormGroup>
                    <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                      <Label for="formPassword" className="mr-sm-2">
                        비밀번호
                      </Label>
                      <Input
                        type="password"
                        name="password"
                        id="formPassword"
                        placeholder="********"
                        onChange={(ev) => setPassword(ev.currentTarget.value)}
                      />
                    </FormGroup>
                    <Button type="submit" color="primary"
                      style={{ width: "100%", marginTop: "20px" }}
                      disabled={isLoading}>
                      {isLoading ? "로그인 중..." : "로그인"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </MobileViewContainer>
  );
};

export default SecretLogin;
