import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table, Col, Row, Nav, Container, Button, Spinner } from "reactstrap";
import { API, BACKEND_HOST } from "../../../api";
import Loader from "../../../Components/Common/Loader";
import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { useInfiniteQuery, useQuery } from "react-query";
import QueryString from "qs";
import moment from "moment";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const CancelList = () => {
  const navigate = useNavigate();

  // const [cancels, setCancels] = React.useState([]);
  const [dataQueryString, setDataQueryString] = useState({
    filter_site: SUBDOMAIN,
  });

  const token = getAccessToken();

  const fetchData = useCallback(
    async (page) => {
      if (!token) {
        throw new Error("로그인이 필요합니다.");
        // navigate(`/`);
      }
      let _queryString = QueryString.stringify(dataQueryString);
      if (_queryString) {
        _queryString = `&${_queryString}`;
      } else {
        _queryString = "";
      }
      const response = await API.get(`/package/user-cancel-app/?page=${page}${_queryString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      console.log("data", data);
      return data;
    },
    [token, dataQueryString]
  );

  const {
    status, data, error, isSuccess, isFetching, isLoading, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch
  } = useInfiniteQuery(["cancel-list", dataQueryString], ({ pageParam = 1 }) => fetchData(pageParam), {
    getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
  });



  return (
    <React.Fragment>
      <MobileViewContainer>
        <Navbar />
        <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
          <Row className="justify-content-center">
            <Col xxl={12} xl={12} md={12} sm={12}>
              <div className="table-responsive">

                {data && isSuccess ? (
                  <>
                    {data?.pages.map((page, index) => (
                      <React.Fragment key={index}>
                        {page.results.map((item, index) => (

                          <div key={item.id} style={{ border: "1px solid #ededed", padding: "20px", borderRadius: "5px" }} className="mt-3 pb-0">
                            <div style={{}}>
                              <p style={{ fontSize: "15px", fontWeight: "600", paddingBottom: "15px", borderBottom: "1px solid #ededed" }}>{item.name} #{item.id}</p>

                              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                                <p style={{ color: "#6d6d6d" }}>취소일</p>
                                <p>
                                  {moment(item.cancelDate).format("YYYY-MM-DD")}
                                </p>
                              </div>

                              <div>
                                {item.reservationDate ? <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                                  <p style={{ color: "#6d6d6d" }}>예약일</p>
                                  <p>{item.reservationDate}</p>
                                </div> : null}
                                {item.spot ? <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                                  <p style={{ color: "#6d6d6d" }}>예약 좌석</p>
                                  <p>{item.spot ? item.spot + "번" : null}</p>
                                </div> : null}

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }}>
                                  <p style={{ color: "#6d6d6d" }}>취소금액</p>
                                  <p>{item.cancelAmount.toLocaleString()}원</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}

                {isLoading || isFetchingNextPage ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : null}

                {isSuccess && data.pages.length === 0 ? (
                  <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
                    <Loader />
                  </div>
                ) : null}

                <div className="d-flex justify-content-center gap-3" style={{ alignItems: "center", height: 50 }}>
                  {hasNextPage ? (
                    <Button color="light" onClick={() => fetchNextPage()}>
                      다음
                    </Button>
                  ) : null}
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </MobileViewContainer>
    </React.Fragment>
  );
};
export default CancelList;
