import React from "react";

import Navbar from "../../../Components/Common/navbar";
import ShoppingOrderProcess from "./OrderProcess";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const OrderShopping = () => {
  return (
    <MobileViewContainer>
      <Navbar />
      <ShoppingOrderProcess />
    </MobileViewContainer>
  );
};

export default OrderShopping;
