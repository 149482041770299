import { API } from "../../../api";
import { nicepayClientId } from "./keys";

function requestPaymentNicePay({
    orderId,
    amount,
    goodsName,
    method='card',
    vbankHolder='나이스',
    returnUrl = '/package/order-nice/',
    errorCb = () => {},
}) {
    if (!window.AUTHNICE) {
        throw new Error('AUTHNICE is not defined');
    }
    if (!nicepayClientId) {
        throw new Error('nicepayClientId is not defined');
    }

    window.AUTHNICE.requestPay({
        clientId: nicepayClientId,
        method: method, // * card, vbank,
        orderId: orderId,
        amount: amount,
        goodsName: goodsName,
        vbankHolder: vbankHolder,
        returnUrl: API.defaults.baseURL + returnUrl,
        fnError: errorCb,
    });
}

export { requestPaymentNicePay };
