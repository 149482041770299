import React, { useCallback, useEffect, useMemo } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

interface CarouselItemType {
    altText?: string;
    caption?: string;
    key?: number;
    src: string;
    display_level?: number;
    link_url?: string;
    title?: string | null;
    description?: string | null;
    data?: any;
}

interface ImageDimensions {
    width: number;
    height: number;
    aspectRatio: number;
}

interface AmCarouselProps {
    interval?: number;
    items: CarouselItemType[];
    style?: React.CSSProperties;
    heightRatio?: number;
    objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
}

export default function AmCarousel({
    interval = 5000,
    items = [],
    style = {},
    heightRatio = 1,
    objectFit = "contain",
}: AmCarouselProps) {
    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const [animating, setAnimating] = React.useState<boolean>(false);
    const [containerWidth, setContainerWidth] = React.useState<number>(0);
    const [imageDimensions, setImageDimensions] = React.useState<ImageDimensions | null>(null);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const loadImage = useCallback((url: string) => {
        const img = new Image();
        img.onload = () => {
            setImageDimensions({
                width: img.width,
                height: img.height,
                aspectRatio: img.height / img.width
            });
        };
        img.src = url;
    }, []);

    useEffect(() => {
        if (items.length > 0) {
            // fix dimension of first image
            loadImage(items[0].src);
        }
    }, [items, loadImage]);

    useEffect(() => {
        if (containerRef.current) {
            console.log("containerRef.current.clientWidth", containerRef.current.clientWidth);
            setContainerWidth(containerRef.current.clientWidth);
        }
    }, [containerRef.current]);

    const imageStyle = useMemo((): React.CSSProperties => {
        const _width = containerWidth;
        const calculatedRatio = imageDimensions?.width ? imageDimensions.height / imageDimensions.width : heightRatio;
        return {
            width: `${_width}px`,
            height: `${_width * calculatedRatio}px`,
            objectFit: objectFit
        };
    }, [containerWidth, heightRatio, objectFit, imageDimensions]);

    const next = (): void => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = (): void => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex: number): void => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const onExiting = (): void => {
        setAnimating(true);
    }

    const onExited = (): void => {
        setAnimating(false);
    }

    if (!items || items.length === 0) return null;


    return (<div ref={containerRef}>
        {items.length === 1 ? <div style={{ ...style, position: "relative" }}>
            {items[0].link_url ?
                <a href={items[0].link_url ? items[0].link_url : "#"} target={items[0].link_url ? "_blank" : "_self"} rel="noreferrer">
                    <img src={items[0].src} alt={items[0].altText} style={{ ...styles.carouselImage, ...imageStyle }} />
                </a>
                : <img src={items[0].src} alt={items[0].altText} style={{ ...styles.carouselImage, ...imageStyle }} />
            }
            {items[0].title ? <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white", padding: "16px 14px 50px 14px" }}>
                {items[0].link_url ? <a href={items[0].link_url} target="_blank" rel="noreferrer"><h5 style={{ textAlign: "center", color: "white" }}>{items[0].title}</h5></a> : <h5 style={{ textAlign: "center", color: "white" }}>{items[0].title}</h5>}
                {items[0].description ? <div style={{ textAlign: "center" }}>{items[0].description}</div> : null}
            </div> : null}
        </div> : <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={interval}
            style={{ ...style }}
            enableTouch={true}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {items.map((item) => {
                return (
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                    >
                        {item.link_url ?
                            <a href={item.link_url ? item.link_url : "#"} target={item.link_url ? "_blank" : "_self"} rel="noreferrer">
                                <img src={item.src} alt={item.altText} style={{ ...styles.carouselImage, ...imageStyle }} />
                            </a>
                            : <img src={item.src} alt={item.altText} style={{ ...styles.carouselImage, ...imageStyle }} />
                        }

                        {item.title ?
                            <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", backgroundColor: "rgba(0, 0, 0, 0.5)", color: "white", padding: "16px 14px 50px 14px" }}>
                                {item.link_url ? <a href={item.link_url} target="_blank" rel="noreferrer"><h5 style={{ textAlign: "center", color: "white" }}>{item.title}</h5></a> : <h5 style={{ textAlign: "center", color: "white" }}>{item.title}</h5>}
                                {item.description ? <div style={{ textAlign: "center" }}>{item.description}</div> : null}
                            </div>
                            : null}
                    </CarouselItem>
                );
            })}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>}
    </div>);
}

const styles: { [key: string]: React.CSSProperties } = {
    carouselImage: {
        width: "100%",
        height: "auto",
    }
};