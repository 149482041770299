import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, NavItem, NavLink } from "reactstrap";
import { API, BACKEND_HOST, SITE_URL } from "../../api";
import classnames from "classnames";
import GiftKakaoList from "../../pages/UserPage/purchaseList/GiftKakaoList";
import GiftPhoneNumList from "../../pages/UserPage/purchaseList/GiftPhoneNumList";
import { getAccessToken } from "../../api/auth";
import { shareKakao } from "../../common/social/kakao/api";

const CouponGiftModal = ({ info, setInfo, visible, setVisible, needRefresh }) => {
  const togModal = () => setVisible(!visible);

  const [customActiveTab, setcustomActiveTab] = useState("2");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const sendUserCoupon = async (couponId, phoneNum) => {
    const phone_num = phoneNum.replaceAll("-", "");

    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/coupon/gift/send/",
        {
          user_coupon_id: couponId,
          phone_num: phone_num,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.data;
      return data;
    } catch (error) {
      console.error(error);
      // Handle errors as needed
      throw error;
    }
  };
  const sendUserCouponKakao = async (couponId, kakaoId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/coupon/gift/send/kakao/",
        {
          coupon_id: couponId,
          kakao_id: kakaoId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.data;
      return data;
    } catch (error) {
      console.error(error);
      // Handle errors as needed
      throw error;
    }
  };

  const giftCouponByUrl = async (couponId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        `/coupon/gift/send/url/`,
        {
          user_coupon_id: couponId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  if (!Object.keys(info).length) return null;

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          <div className="my-3 d-flex justify-content-center  "></div>
          <ul className="list-group">
            <li className="list-group-item">쿠폰이름: {info?.coupon_name}</li>
            <li className="list-group-item">사용가능한 지점 : {info?.state}</li>
            <li className="list-group-item">
              할인금액 : {info?.coupon.discount_type === "price" ? `${info.coupon.discount_amount.toLocaleString()}원 할인쿠폰` : `${info.coupon.discount_rate}% 할인쿠폰`}
            </li>
            <li className="list-group-item">만료기한 : {info.coupon.expire_date}</li>
            {info.coupon.bound_type === "reservation" ? (
              <li className="list-group-item">
                예약가능기간: {info.coupon.use_start_date} ~ {info.coupon.use_end_date}
              </li>
            ) : null}

            <li className="list-group-item">사용정보 : {info.coupon.min_purchase.toLocaleString()}원 이상 구매시 사용가능</li>
            <li className="list-group-item">이용정보 : {info.state}</li>
          </ul>

          <div style={{ marginTop: "24px" }}></div>
          <div className="d-flex justify-content-center mx-1 mb-4">
            <Button
              style={{
                fontFamily: "Gmarket-M",
                backgroundColor: "#f9e000",
                borderWidth: 0,
                color: "#444",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 20px",
                gap: "10px",
              }}
              onClick={() => {
                giftCouponByUrl(info.id).then((data) => {
                  console.log("giftCouponByUrl", data);
                  shareKakao({
                    title: `${info.coupon_name}`,
                    description: `쿠폰상품을 선물 받았습니다.`,
                    imageUrl: info.coupon?.image ? info.coupon.image : "",
                    shareUrl: `${SITE_URL}/gift/coupon/${data.gift_id}/`,
                  });
                }).catch((error) => {
                  console.error(error);
                  alert("선물하기에 실패했습니다.");
                }).finally(() => {
                  setVisible(false);
                  needRefresh();
                });
              }}
            >
              <i className="ri-gift-fill"></i>
              카카오톡으로 선물보내기
            </Button>
          </div>

          <div style={{ marginTop: "24px" }}></div>

          <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10" style={{ alignItems: "end" }}>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="fs-20">
                  <i className="ri-kakao-talk-fill"></i>
                </span>{" "}
                카톡친구 선물
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                전화번호로 선물
              </NavLink>
            </NavItem>
          </Nav>

          {customActiveTab === "1" ? <GiftKakaoList sendItemKakao={sendUserCouponKakao} {...{ info, setInfo, visible, setVisible, needRefresh }} /> : null}
          {customActiveTab === "2" ? <GiftPhoneNumList sendItem={sendUserCoupon} {...{ info, setInfo, visible, setVisible, needRefresh }} /> : null}
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default CouponGiftModal;
