import React from "react";

import Navbar from "../../../Components/Common/navbar";
import MultiOrderProcess from "./MultiOrderProcess";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const OrderTicketMultiPage = () => {
  return (
    <MobileViewContainer>
      <Navbar />
      <MultiOrderProcess />
    </MobileViewContainer>
  );
};

export default OrderTicketMultiPage;
