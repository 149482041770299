import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, Label, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BACKEND_HOST } from "../../../api";
import { useUserStore } from "../../../store/store";
import { noticeService } from "../../../api/services/notice.service";

const NoticeDetailSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [notice, setNotice] = React.useState({});
  const [permission] = useUserStore((state) => [state.permission]);
  const [deleteModal, setDeleteModal] = React.useState(false);

  function togDeleteModal() {
    setDeleteModal(!deleteModal);
  }

  const deleteData = async () => {
    try {
      await noticeService.deleteNoticeById(id);
      navigate("/notice");
    } catch (error) {
      console.error(error);
      alert("삭제에 실패했습니다.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`/notice/content/${id}/`);
        const data = response.data;
        setNotice(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <React.Fragment>
      <div className="mt-4">
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h1 className="mb-3 ff-secondary fw-semibold lh-base">{notice.title}</h1>
                      </div>
                    </div>
                    {!!permission && permission.permission > 0 ? (
                      <div className="col-sm-3 ms-auto">
                        <div className="d-flex justify-content-sm-end gap-2">
                          <Button
                            className="btn-soft-success add-btn me-1"
                            onClick={() => {
                              navigate(`/notice/form/${id}`);
                            }}
                            id="edit-btn"
                          >
                            수정하기
                          </Button>
                          <Button
                            className="btn-soft-danger add-btn me-1"
                            onClick={() => {
                              togDeleteModal();
                            }}
                            id="delete-btn"
                          >
                            삭제하기
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </Row>
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <Label style={{ whiteSpace: "pre-wrap" }}>{`작성일 ${notice.writtenDate}\n`}</Label>
                      <span style={{ whiteSpace: "pre-wrap", color: "gray" }}>{`\n작성자: ${notice.writerName}`}</span>
                    </div>
                  </Row>
                  <br />
                  <br />
                  <hr />
                  <Row className="g-4 align-items-center">
                    <div dangerouslySetInnerHTML={{ __html: notice.content }} />
                  </Row>
                  <Row className="g-4 align-items-center">
                    <div className="col-sm-3 ms-auto">
                      <div className="d-flex justify-content-sm-end gap-2">
                        <Button
                          color="white"
                          className="btn-ghost-dark add-btn me-1"
                          onClick={() => {
                            navigate(`/notice`);
                          }}
                          id="edit-btn"
                        >
                          목록보기
                        </Button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Delete Modal */}
      <Modal
        id="topmodal"
        isOpen={deleteModal}
        toggle={() => {
          togDeleteModal();
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            togDeleteModal();
          }}
        >
          삭제하기
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" style={{ width: "120px", height: "120px" }} colors="primary:#f7b84b,secondary:#f06548"></lord-icon>
          <div className="mt-4">
            <h5 className="mb-3">해당 공지사항을 삭제하시겠습니까?</h5>
            {/* <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p> */}
            <div className="hstack gap-2 justify-content-center">
              <button
                className="btn bg-gradient-light fw-medium"
                onClick={() => {
                  togDeleteModal();
                }}
              >
                <i className="ri-close-line me-1 align-middle"></i>닫기
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  deleteData();
                  setDeleteModal(false);
                }}
              >
                삭제하기
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default NoticeDetailSection;
