import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Common/navbar";
import styled from "styled-components";
import { Button, Col } from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { SITE_ID } from "../../../assets/meta/meta";
import { giftService } from '../../../api/services/gift.service';
import { siteService } from '../../../api/services/site.service';
import { useAuthContext } from "../../../Components/AuthContext";
import MobileViewContainer from "../../../Components/MobileViewContainer";

const CouponWrapper = styled.div`
  width: 90%;
  min-height: 176px;
  position: relative;
  background-color: #fff;
  border: 1px solid #f6475f;
  padding: 16px 16px 20px 20px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #f6475f;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #fff0f2;
  }
`;

const CouponContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const GiftCouponPage = () => {
    const location = useLocation();

    const { isUserLogin } = useAuthContext();
    const [couponItem, setCouponItem] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [download, setDownload] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const [siteName, setSiteName] = React.useState("");
    const [siteLogo, setSiteLogo] = React.useState("");
    const [siteSlogan, setSiteSlagon] = React.useState("");
    useEffect(() => {
        siteService.getSiteById(SITE_ID)
            .then((response) => {
                setSiteName(response.data.name);
                setSiteLogo(response.data.logo);
                setSiteSlagon(response.data.slogan);
                console.log("get site", response.data);
            })
            .catch((error) => console.log(error));
    }, []);

    React.useEffect(() => {
        if (isUserLogin) {
            fetchGiftData(id);
        }
    }, [isUserLogin, id]);

    const fetchGiftData = (id) => {
        setIsLoading(true);
        giftService.getCouponShareGift(id)
            .then((response) => {
                console.log("get coupons", response.data);
                setCouponItem(response.data);
            })
            .catch((e) => {
                console.log(e);
                setError(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleGiftReception = (id) => {
        giftService.putReceiveVoucherGift(id)
            .then((response) => {
                console.log("receive-coupon-gift", response);
                alert("선물을 받았습니다.");
                fetchGiftData(id);
            })
            .catch((e) => {
                console.log(e);
                alert("선물을 받을 수 없습니다.");
            });
    };

    return (
        <MobileViewContainer>
            <Navbar />

            {!isUserLogin ? (
                <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
                        <span style={{ color: "#ff5a5f" }}>선물받기</span>
                        <br />
                        쿠폰
                    </h2>
                    <CouponWrapper>
                        <CouponContent>

                            <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#999" }}>로그인 하여 선물을 확인하세요.</p>
                            </Col>

                            <Col>
                                <Button
                                    style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", float: "right" }}
                                    onClick={() => {
                                        navigate(`/login?path=${location.pathname}`);
                                    }}
                                >
                                    로그인 하기
                                </Button>
                            </Col>
                        </CouponContent>
                    </CouponWrapper>
                </div>
            ) : (
                <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    {!isLoading && couponItem && couponItem !== null ? (
                        <>
                            <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
                                <span style={{ color: "#ff5a5f" }}>선물받기</span>
                                <br />
                                쿠폰
                            </h2>

                            <div style={{ width: "100%", paddingInline: "32px", marginBottom: "12px" }}>
                                <span style={{ fontWeight: "600", fontSize: "16px" }}>{couponItem?.sender_username} </span>
                                <span>님이 선물을 보냈습니다.</span>
                            </div>

                            <CouponWrapper>
                                <CouponContent>

                                    <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        {couponItem?.coupon.image ? <div style={{ marginRight: "14px" }}>
                                            <img src={couponItem.coupon.image} alt="Voucher" style={{ width: "100%", marginBottom: "10px", maxWidth: "96px" }} />
                                        </div> : null}
                                        <div>
                                            <p style={{ fontWeight: "600", fontSize: "20px" }}>{couponItem?.coupon_name}</p>

                                            <p style={{ marginTop: "10px", color: "#999" }}>
                                                만료일: {couponItem?.expire_date}
                                            </p>
                                        </div>
                                    </Col>

                                    {!couponItem.user ? (
                                        <Button
                                            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", opacity: download ? 0.5 : 1, float: "right" }}
                                            onClick={() => handleGiftReception(id)}
                                        >
                                            선물받기
                                        </Button>
                                    ) : (
                                        <Button style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", float: "right", opacity: 0.5 }} disabled>
                                            이미 선물을 받으셨습니다.
                                        </Button>
                                    )}
                                </CouponContent>
                            </CouponWrapper>
                            <div style={{ textAlign: "start", width: "90%", margin: "30px auto", lineHeight: "28px", color: "#000" }}>
                                <p style={{ fontWeight: "600" }}>유의사항</p>
                                <div dangerouslySetInnerHTML={{ __html: couponItem?.coupon.descript }}></div>
                            </div>
                        </>
                    ) : isLoading ? (
                        <div>로딩중...</div>
                    ) : (
                        <div>정보를 찾을 수 없습니다.</div>
                    )}
                </div>
            )}
        </MobileViewContainer>
    );
};

export default GiftCouponPage;
