import React, { useCallback, useEffect } from "react";
import { Modal, Button, Alert } from "reactstrap";
import { couponService } from "../../api/services/coupon.service";
import { useInfiniteQuery } from "react-query";

const CouponShoppingSelectModal = (props) => {
  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await couponService.getCouponsByPage(pageParam);
      return response.data;
    },
    []
  );

  const { status, data, error, isSuccess, isFetching, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } = useInfiniteQuery(
    ["UserCouponList"],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      //   console.log("data", data);
      return data;
    },
    {
      // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.next) {
          const match = lastPage.next.match(/page=(\d+)/);
          if (match) {
            return match[1];
          }
        }
        return undefined;
      },
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  useEffect(() => {
    console.log("coupon", props.ticket);
  }, [props.ticket]);

  // useEffect(() => {}, [props.selectCoupon, props.ticket]);

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3 pt-4">
          {/* <h3>티켓 디테일</h3> */}
          <ul className="list-group">
            {data &&
              isSuccess &&
              data.pages.map((page, i) =>
                page.results
                  .filter((coupons, j) => coupons.coupon.bound_type === "shopping" && coupons.state === "사용안함")
                  .map((coupon, j) => {
                    const is_expired = new Date(coupon.coupon.expire_date) < new Date();
                    const is_valid_category = coupon.coupon.shopping_categories.find((category) => category === props.targetItem.category);
                    const is_max_purchase = coupon.coupon.min_purchase <= props.targetItem.price * props.targetItem.quantity;
                    const is_vaild_discount =
                      coupon.coupon.discount_type === "price"
                        ? props.targetItem.price * props.targetItem.quantity - coupon.coupon.discount_amount >= 0
                        : props.targetItem.price * props.targetItem.quantity * (100 - coupon.coupon.discount_rate / 100) >= 0;

                    const isSelectedCoupon = props.selectCoupon.find(
                      (coupons) => coupons.product_id === props.targetItem.product_id && coupons.option_id !== props.targetItem.option_id && coupons.coupon_id === coupon.id
                    );

                    const disabled = is_expired || !is_valid_category || !is_max_purchase || !is_vaild_discount || isSelectedCoupon;

                    return (
                      <li key={j} className="list-group-item" style={{ opacity: disabled ? 0.5 : 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ marginTop: "15px" }}>
                          <p style={{ fontSize: "15px", fontFamily: "Gmarket-M", margin: 0, marginBottom: "5px", maxWidth: "95%" }}>{coupon.coupon.title}</p>
                          <p style={{ margin: 0, marginBottom: "5px" }}>만료기한 {coupon.coupon.expire_date}까지</p>
                          <p style={{ fontSize: "15px", fontFamily: "Gmarket-M", color: "#ff5a5f", margin: 0, marginBottom: "5px" }}>
                            {coupon.coupon.discount_type === "price" ? coupon.coupon.discount_amount.toLocaleString() + "원 할인" : coupon.coupon.discount_rate + "% 할인"}
                          </p>
                          <p style={{ margin: 0 }}>구매금액 {coupon.coupon.min_purchase.toLocaleString()}원 이상 사용가능</p>
                          {coupon.coupon.discount_type === "percentage" ? <p>최대 {coupon.coupon.max_discount.toLocaleString()}원까지 할인</p> : null}
                        </div>

                        {disabled ? (
                          <p>사용할 수 없는 쿠폰입니다.</p>
                        ) : (
                          <input
                            type="radio"
                            name="coupon"
                            value={coupon.id}
                            disabled={disabled}
                            checked={
                              props.selectCoupon?.length > 0
                                ? props.selectCoupon.find(
                                    (coupons) => coupons.coupon_id === coupon.id && coupons.product_id === props.targetItem.product_id && coupons.option_id === props.targetItem.option_id
                                  )
                                : false
                            }
                            onChange={() => {
                              props.toggleShoppingDiscountCouponSelection(
                                props.targetItem.product_id,
                                coupon.id,
                                props.targetItem.option_id,
                                coupon.coupon.discount_amount,
                                coupon.coupon.discount_type,
                                coupon.coupon.min_purchase,
                                coupon.coupon.discount_rate,
                                coupon.coupon.max_discount
                              );
                            }}
                          />
                        )}
                      </li>
                    );
                  })
              )}
          </ul>
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4 pb-4">
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            onClick={() => {
              const productIdToRemove = props.targetItem.product_id;
              const updatedSelectCoupon = props.selectCoupon.filter((coupon) => coupon.product_id === productIdToRemove && coupon.option_id !== props.targetItem.option_id);
              props.setSelectCoupon(updatedSelectCoupon);
              props.setVisible(!props.visible);
            }}
          >
            취소
          </Button>
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default CouponShoppingSelectModal;
